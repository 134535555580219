import {
  DetailConfig,
  DynamicComponentConfig,
  EditorConfig,
  ListConfig,
  Nx3Api,
  ReferenceListsComponent,
  ResourceExtension,
  StaticHtmlContentComponent,
} from '@nx3/nx3-core-ui';

/**
 * Abstraction for resource extensions used in portal
 */
export abstract class AbstractPortalExtension extends ResourceExtension {
  constructor(resourceName: string, public nx3: Nx3Api) {
    super(resourceName, nx3);
    this.enableDetail = false;
  }

  configureList(config: ListConfig) {
    config.setFixedView(false);
    config.table.toolDetail = false;
    config.enableNotifications = false;
    config.enableCount = false;
    config.enableDuplicate = false;
    config.enableGuidedTour = false;
    config.enableListSettings = false;
    config.enableIndexedSearch = false;
    config.enableFilterModeToggle = false;

    config.componentExtensions.addNorthComponent(
      new DynamicComponentConfig(
        StaticHtmlContentComponent,
        { key: this.resourceName + '_list_description' },
        config.componentExtensions.createModelMapping(
          ['resourceConfig.server'],
          ['server']
        )
      )
    );
  }

  configureDetail(config: DetailConfig) {
    config.enableNotifications = false;
    config.enableDuplicate = false;
    config.enableProcessDetail = false;
    config.disabledComponents = [ReferenceListsComponent];
  }

  configureEditor(config: EditorConfig) {
    config.updateMessageKey = 'nx3_message_save_successful';
    config.saveMessageAsToast = true;
    config.form.formControlOptions = { updateOn: 'change' };
    config.componentExtensions.addNorthComponent(
      new DynamicComponentConfig(
        StaticHtmlContentComponent,
        { key: this.resourceName + '_editor_description' },
        config.componentExtensions.createModelMapping(
          ['resourceConfig.server'],
          ['server']
        )
      )
    );
  }
}
