import {
  DecisionUserTaskExtension,
  Nx3Api
} from '@nx3/nx3-core-ui';

export class DocumentDecisionUserTaskExtension extends DecisionUserTaskExtension {
  constructor(public nx3: Nx3Api, resourceName = 'documentdecisionusertask') {
    super(nx3, resourceName);
  }
}
