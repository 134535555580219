import { ListConfig, Nx3Api, Nx3UserTaskExtension } from '@nx3/nx3-core-ui';

export class UserTaskExtension extends Nx3UserTaskExtension {
  constructor(public nx3: Nx3Api) {
    super(nx3, 'nx3usertask');
  }

  configureList(config: ListConfig) {
    super.configureList(config);
    config.resetOnInit = true;
  }

}
