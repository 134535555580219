<div>

  <label class="mt-2" translate="nx3_message_finish_tender"></label>
  <div class="row">
    <div class="col-6">
      <select class="form-control form-control-sm" [(ngModel)]="finishWithWinner" (change)="selected()">
        <option [ngValue]="true">
          <span translate="nx3_label_finish_with_winner"></span>
        </option>
        <option [ngValue]="false">
          <span translate="nx3_label_finish_without_winner"></span>
        </option>
      </select>
    </div>
    <div class="col-6" style="align-self: center;">
      <input
        style="vertical-align: middle;"
        class="me-2"
        type="checkbox"
        [(ngModel)]="informLosers"
      />
      <span translate="nx3_label_inform_losers"></span>
    </div>
  </div>

  <label class="mt-2" translate="property_closingNote_label"></label>:
  <textarea class="form-control form-control-sm d-inline" [(ngModel)]="closingNote"></textarea>
  <small class="form-text nx3-field-help ms-1">
    <nx3-icon class="me-1" icon="question-circle"></nx3-icon>
    <span translate="property_closingNote_helptext"></span>
  </small>
</div>
