import { Component, OnInit } from '@angular/core';
import {
  EntityPage,
  Nx3SearchComposition,
  Nx3SearchCriteria,
  Nx3SearchFilter,
  Nx3SearchOperator,
  ResourceConfig,
} from '@nx3/nx3-client';
import {
  AbstractEntityComponent,
  ExtensionService,
  Nx3Api,
} from '@nx3/nx3-core-ui';

@Component({
  selector: 'app-message-queue-informations',
  templateUrl: './message-queue-informations.component.html',
  styleUrls: ['./message-queue-informations.component.scss'],
})
export class MessageQueueInformationsComponent
  extends AbstractEntityComponent
  implements OnInit
{
  entityPage: EntityPage;

  duplicateIntegrationFailed = false;
  currentDuplicateState;

  exchangeIntegrationFailed = false;
  currentExchangeState;

  partnernumberIntegrationFailed = false;
  currentPartnernumberState;

  constructor(public nx3: Nx3Api, protected extensions: ExtensionService) {
    super(nx3, extensions);
  }

  ngOnInit(): void {
    this.nx3.client.entity
      .resourceConfigByName(
        this.nx3.config.registrationServer,
        'nx3persistentmessage'
      )
      .subscribe((resourceConfig: ResourceConfig) => {
        this.nx3.client.entity
          .getFilter(resourceConfig, true)
          .subscribe((filter: Nx3SearchFilter) => {
            const criteria = new Nx3SearchCriteria();
            criteria.key = 'context';
            criteria.operator = Nx3SearchOperator.EQUAL;
            criteria.composition = Nx3SearchComposition.AND;
            criteria.values = [
              {
                referenceId: this.entity.id,
                type: 'de.seriea.nx3.transa.businesspartner.carrier.Carrier',
              },
            ];
            filter.criteria.criteria = [criteria];
            this.nx3.client.entity
              .filterSearch(resourceConfig, filter)
              .subscribe((entityPage: EntityPage) => {
                entityPage.content.sort((a, b) => {
                  return a.createdAt.getTime() - b.createdAt.getTime();
                });
                this.entityPage = entityPage;
                for (const persistentmessage of entityPage.content) {
                  if (persistentmessage.handlerType.includes('Dup')) {
                    if (persistentmessage.state === 'ERROR') {
                      this.duplicateIntegrationFailed = true;
                    } else if (persistentmessage.state === 'PROCESSED') {
                      this.currentDuplicateState = persistentmessage;
                    }
                  }
                  if (persistentmessage.handlerType.includes('Ex')) {
                    if (persistentmessage.state === 'ERROR') {
                      this.exchangeIntegrationFailed = true;
                    } else if (persistentmessage.state === 'PROCESSED') {
                      this.currentExchangeState = persistentmessage;
                    }
                  }
                  if (persistentmessage.handlerType.includes('No')) {
                    if (persistentmessage.state === 'ERROR') {
                      this.partnernumberIntegrationFailed = true;
                    } else if (persistentmessage.state === 'PROCESSED') {
                      this.currentPartnernumberState = persistentmessage;
                    }
                  }
                }
              });
          });
      });
  }
}
