import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import {
  Nx3BeanModel,
  Nx3Entity,
  ResourceConfig,
  ResourceModel,
} from '@nx3/nx3-client';
import { ResourceResolver } from '@nx3/nx3-core-ui';
import { Observable, of, throwError } from 'rxjs';
import { catchError, flatMap, take } from 'rxjs/operators';
/**
 * Resolver to be used for page with partner number
 *
 * @author daniel.peer(at)serie-a.de
 */
@Injectable({
  providedIn: 'root',
})
export class CarrierResolver extends ResourceResolver {
  resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
    const serverName = 'rs1';
    const resourceName = 'carrier';
    return this.ensureBootstrap(route)
      .pipe(
        flatMap((bootstrap: any[]) => {
          return this.getResourceConfig(serverName, resourceName).pipe(
            flatMap((resourceConfig: ResourceConfig) => {
              return this.getEntity(resourceConfig, route).pipe(
                flatMap((entity: Nx3Entity) => {
                  return this.getResourceModel(resourceConfig).pipe(
                    flatMap((resourceModel: ResourceModel) => {
                      return this.getMetadata(resourceConfig).pipe(
                        flatMap((metadata: Nx3BeanModel) => {
                          return of([
                            resourceConfig,
                            resourceModel,
                            metadata,
                            entity,
                          ]);
                        })
                      );
                    })
                  );
                })
              );
            })
          );
        })
      )
      .pipe(take(1))
      .pipe(
        catchError((error) => {
          error.resolverError = true;
          return throwError(error);
        })
      );
  }

  getEntity(
    resourceConfig: ResourceConfig,
    route: ActivatedRouteSnapshot
  ): Observable<Nx3Entity> {
    const partnerNr = route.paramMap.get('partnerNr');
    return this.nx3.client.entity
      .get(resourceConfig, '/partner/' + partnerNr)
      .pipe(
        catchError((error) => {
          this.nx3.errorhandler.notFound(error, resourceConfig, partnerNr);
          return throwError(error);
        })
      );
  }
}
