<table class="table">
  <thead>
    <tr>
      <th scope="col">{{ 'property_partnerNr_label' | translate }}</th>
      <th scope="col">{{ 'property_name_label' | translate }}</th>
      <th scope="col">{{ 'property_street_label' | translate }}</th>
      <th scope="col">{{ 'property_zipCode_label' | translate }}</th>
      <th scope="col">{{ 'property_city_label' | translate }}</th>
      <th scope="col">{{ 'property_country_label' | translate }}</th>
      <th scope="col">{{ 'property_transaOffice_label' | translate }}</th>
      <th scope="col">{{ 'property_vatId_label' | translate }}</th>
      <th scope="col"></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let result of object">
      <td>{{ result.partnernumber }}</td>
      <td>{{ result.name }}</td>
      <td>{{ result.street }}</td>
      <td>{{ result.zipCode }}</td>
      <td>{{ result.city }}</td>
      <td>{{ result.countryCode }}</td>
      <td>{{ result.transaOffice }}</td>
      <td>{{ result.vatId }}</td>
      <th scope="row">
        <button
          class="btn btn-success btn-sm"
          (click)="chooseAndComplete(result.partnernumber)"
        >
          {{ 'nx3_button_choose_and_complete_task' | translate }}
        </button>
      </th>
    </tr>
  </tbody>
</table>
