import { EmployeeExtension, ListEvent, Nx3Api } from '@nx3/nx3-core-ui';
import { CustomUserdetailsFieldComponent } from '../components/custom-userdetails-field/custom-userdetails-field.component';

export class CustomEmployeeExtension extends EmployeeExtension {
  constructor(public nx3: Nx3Api) {
    super(nx3);
  }

  postConstruct(): void {
    super.postConstruct();
    this.config.createUser = false;
  }

  onListInit(event: ListEvent): void {
    event.component.metadata.getColumnByName('virtual.userDetails').componentType = CustomUserdetailsFieldComponent;
  }
}
