<div class="mb-3">
  <nx3-list
    *ngIf="initialized"
    #list
    [filter]="filter"
    [config]="listConfig"
    [resourceModel]="carrierResourceModel"
    [resourceConfig]="carrierResourceConfig"
    [metadata]="carrierBeanModel"
    [specificationMetadata]="carrierSpecificationMetadata"
    [deleteConfig]="deleteConfig"
  ></nx3-list>
</div>
