import {
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Nx3Entity, ResourceConfig } from '@nx3/nx3-client';

import {
  AbstractEntityComponent,
  ColorService,
  ExtensionService,
  Map,
  Nx3Api,
} from '@nx3/nx3-core-ui';

@Component({
  selector: 'lib-nuts-regions',
  templateUrl: './nuts-regions.component.html',
  styleUrls: ['./nuts-regions.component.scss'],
})
export class NutsRegionsComponent
  extends AbstractEntityComponent
  implements OnDestroy, OnInit
{
  @Input() mapHeight = 600;

  map: Map;
  mapId = 'map-' + Date.now();
  defaultColor = '--nx3-color-theme-blue';
  infoWindow: google.maps.InfoWindow;
  loadedClassifications: string[] = [];
  selectedClassififcation: string;

  fromKey: string;
  toKey: string;

  nutsLoaded = [];

  resourceConfig: ResourceConfig;

  showInList = false;

  selectedCoordinates: google.maps.LatLngLiteral = null;

  constructor(
    public nx3: Nx3Api,
    public extensions: ExtensionService,
    public colorService: ColorService
  ) {
    super(nx3, extensions);
  }

  ngOnDestroy(): void {
    this.nx3.geo.destroyMap(this.map);
    this.nx3.client.events.unregister('relationChanged', this);
  }

  ngOnInit() {
    this.nx3.geo.initializeGeoService().subscribe(() => {
      setTimeout(() => {
        this.map = this.nx3.geo.createMap(this.mapId);
        this.infoWindow = new google.maps.InfoWindow();

        this.resourceConfig = ResourceConfig.forClass(
          'rs1',
          'de.seriea.nx3.core.region.domain.Nx3Region'
        );

        if (
          (this as any).object?.fromRegion &&
          (this as any).object?.toRegion
        ) {
          this.selectedClassififcation = (
            this as any
          ).object.fromRegion.classification;
          this.fromKey = (this as any).object.fromRegion.key;
          this.toKey = (this as any).object.toRegion.key;
          this.createRegionGeometryObject(
            (this as any).object.fromRegion,
            true
          );
          this.createRegionGeometryObject((this as any).object.toRegion, true);
          this.map.polylines.push(
            new google.maps.Polyline({
              icons: [
                {
                  icon: { path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW },
                  offset: '100%',
                },
              ],
              map: this.map.map,
              visible: true,
              zIndex: 1000,
              path: [
                {
                  lat: (this as any).object.fromRegion.centerPointLat,
                  lng: (this as any).object.fromRegion.centerPointLon,
                },
                {
                  lat: (this as any).object.toRegion.centerPointLat,
                  lng: (this as any).object.toRegion.centerPointLon,
                },
              ],
            })
          );
          this.coloringRegion();
        } else if (this.showInList) {
          this.selectedClassififcation = 'nuts0';
          this.changeClassification(this.selectedClassififcation);
        }
      });
    });

    this.nx3.client.events.on(
      'relationChanged',
      () => {
        this.relationChanged();
      },
      this
    );

    this.nx3.client.events.on(
      'relationsLoaded',
      (event) => {
        const fromRegions = [];
        const toRegions = [];
        for (const relation of event.data.relations) {
          fromRegions.push(relation.fromRegion);
          toRegions.push(relation.toRegion);
        }
        this.printRelations(fromRegions, toRegions);
      },
      this
    );

    this.nx3.client.events.on(
      'RESOURCE_BEFORE_SEARCH',
      (event) => {
        if (
          event.data?.criteria?.criteria?.length > 0 &&
          event.data?.criteria?.criteria[0]?.key === 'classification' &&
          event.data?.criteria?.criteria[0]?.values[0] !==
            this.selectedClassififcation
        ) {
          this.selectedClassififcation =
            event.data.criteria.criteria[0].values[0];
          this.changeClassification(this.selectedClassififcation);
        }
      },
      this
    );
  }

  relationChanged() {
    const fromRegions = [];
    const toRegions = [];
    for (const property in (this as any).object) {
      if (property.startsWith('fromRegion') && (this as any).object[property]) {
        fromRegions.push((this as any).object[property]);
      }
    }
    for (const property in (this as any).object) {
      if (property.startsWith('toRegion') && (this as any).object[property]) {
        toRegions.push((this as any).object[property]);
      }
    }

    this.printRelations(fromRegions, toRegions);
  }

  printRelations(fromRegions, toRegions) {
    if (fromRegions.length === toRegions.length) {
      this.nx3.geo.clearMap(this.map);
      for (const [i] of fromRegions.entries()) {
        if (fromRegions[i] && toRegions[i]) {
          this.createRegionGeometryObject(fromRegions[i], true);
          this.createRegionGeometryObject(toRegions[i], true);
          this.map.polylines.push(
            new google.maps.Polyline({
              icons: [
                {
                  icon: { path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW },
                  offset: '100%',
                },
              ],
              map: this.map.map,
              visible: true,
              zIndex: 1000,
              path: [
                {
                  lat: fromRegions[i].centerPointLat,
                  lng: fromRegions[i].centerPointLon,
                },
                {
                  lat: toRegions[i].centerPointLat,
                  lng: toRegions[i].centerPointLon,
                },
              ],
            })
          );
        }
      }
    }
  }

  createRegionGeometryObject = (region, initVisibility) => {
    const clickEvent = (polygon) => {
      this.clickOnRegion(region, polygon);
    };

    const objectProperties = {
      regionKey: region.key,
      classification: region.classification,
    };
    if (region.key == this.fromKey || region.key == this.toKey) {
      initVisibility = true;
    }
    this.createGeometryObject(
      this.map,
      region.area,
      this.defaultColor,
      objectProperties,
      initVisibility,
      clickEvent
    );
  };

  clickOnRegion = (region, polygon) => {
    if (this.showInList) {
      this.infoWindow.addListener('domready', () => {
        // eslint-disable-next-line unicorn/prefer-query-selector
        const selectRegion = document.getElementById('selectRegion');
        // eslint-disable-next-line unicorn/prefer-add-event-listener
        selectRegion.onclick = () => {
          this.selectRegion(region);
          this.selectedCoordinates = {
            lat: region.centerPointLat,
            lng: region.centerPointLon,
          };
          this.changeVisibleClassification();
        };
      });

      const selectButtonLabel =
        this.nx3.i18n.translate.instant('nx3_button_select');
      const buttons =
        '<button id="selectRegion" class="btn btn-sm btn-primary mb-2">' +
        '<span>' +
        selectButtonLabel +
        '</span>' +
        '</button>';
      this.infoWindow.setContent(
        '<h6 style="color: var(--nx3-color-theme-dark)">' +
          region.localizedName.text +
          '</h6>' +
          buttons
      );
      this.infoWindow.setPosition({
        lat: region.centerPointLat,
        lng: region.centerPointLon,
      });

      this.infoWindow.open(this.map, polygon);
    }
  };

  selectRegion(region: Nx3Entity) {
    this.nx3.client.events.fireEvent('setRegion', region.key);
    this.infoWindow.close();
  }

  coloringRegion() {
    for (const polyline of this.map.polylines) {
      let color = this.defaultColor;
      if (this.fromKey && polyline.regionKey === this.fromKey) {
        color = '--nx3-color-theme-dark-green';
      }
      if (this.toKey && polyline.regionKey === this.toKey) {
        color = '--nx3-color-theme-red';
      }

      polyline.set('fillColor', this.colorService.value(color));
    }
  }

  changeClassification(classification: string, buttonClick = false) {
    if (!this.map) {
      return;
    }
    this.infoWindow?.close();
    this.selectedClassififcation = classification;

    const storageKey = this.nx3.client.storage.getUserBoundKey(
      'rs1',
      this.nx3.client.auth.getUser().username,
      `regions_${this.selectedClassififcation}`
    );

    if (!this.nutsLoaded.includes(this.selectedClassififcation)) {
      if (this.nx3.client.storage.session.exists(storageKey)) {
        const regions = JSON.parse(
          this.nx3.client.storage.session.get(storageKey)
        );
        for (const region of regions) {
          this.createRegionGeometryObject(region, true);
          this.coloringRegion();
        }
        this.nutsLoaded.push(classification);
      } else {
        this.nx3.spinner.show(
          this.nx3.i18n.translate.instant('nx3_label_load_regions') + ' ...'
        );
        this.nx3.client.rest
          .post(
            'rs1',
            '/api/region/classification/' + this.selectedClassififcation,
            {}
          )
          .subscribe((regions) => {
            this.nx3.spinner.hide();
            for (const region of regions) {
              this.createRegionGeometryObject(region, true);
              this.coloringRegion();
            }
            this.nx3.client.storage.session.set(
              storageKey,
              JSON.stringify(regions)
            );
            this.nutsLoaded.push(classification);
          });
      }
      this.changeVisibleClassification();
    } else {
      this.changeVisibleClassification();
    }
  }

  changeVisibleClassification() {
    const defaultColor = this.colorService.value(this.defaultColor);

    for (const polyline of this.map.polylines) {
      if (
        polyline.regionKey == this.fromKey ||
        polyline.regionKey == this.toKey
      ) {
        polyline.setVisible(true);
      } else if (polyline.classification === this.selectedClassififcation) {
        polyline.set('fillColor', defaultColor);
        polyline.setVisible(true);
      } else {
        polyline.set('fillColor', defaultColor);
        polyline.setVisible(false);
      }
    }
  }

  createGeometryObject(
    map: Map,
    geometry: any,
    color?: string,
    objectProperties?: object,
    visible = true,
    clickEvent?: (e) => any
  ) {
    let geometryObj = null;
    if (!color) {
      color = '--nx3-color-theme-blue';
    }
    color = geometry.color ?? this.colorService.value(color);
    const filled = geometry.filled;

    switch (geometry.type) {
      case 'Polygon': {
        geometryObj = this.getGeometryObject(
          geometry.coordinates[0],
          color,
          filled,
          visible,
          clickEvent
        );
        for (const [key, value] of Object.entries(objectProperties)) {
          geometryObj[key] = value;
        }

        geometryObj.setMap(map.map);
        map.polylines.push(geometryObj);
        break;
      }

      case 'MultiPolygon': {
        for (const coordinate of geometry.coordinates) {
          geometryObj = this.getGeometryObject(
            coordinate[0],
            color,
            filled,
            visible,
            clickEvent
          );
          for (const [key, value] of Object.entries(objectProperties)) {
            geometryObj[key] = value;
          }
          geometryObj.setMap(map.map);
          map.polylines.push(geometryObj);
        }
        break;
      }

      // TODO implement more shapes like Polygon...
    }

    return geometryObj;
  }

  getGeometryObject(
    coordinates: any[],
    color: string,
    filled: boolean,
    visible = true,
    clickEvent?: (e) => any
  ) {
    const coords = [];
    for (const coord of coordinates) {
      coords.push({
        lat: coord[1],
        lng: coord[0],
      });
    }

    const polygon = new google.maps.Polygon({
      clickable: true,
      paths: coords,
      visible: visible,
      strokeColor: filled ? '#fff' : color,
      strokeOpacity: 0.8,
      strokeWeight: 1,
      fillColor: color,
      fillOpacity: filled ? 1 : 0.35,
    });

    if (clickEvent) {
      google.maps.event.addListener(polygon, 'click', () => {
        clickEvent(polygon);
      });
    }

    return polygon;
  }

  @HostListener('mousewheel', ['$event'])
  onMouseWheelChrome(event: any): void {
    this.mouseWheelFunc(event);
  }

  @HostListener('DOMMouseScroll', ['$event'])
  onMouseWheelFirefox(event: any): void {
    this.mouseWheelFunc(event);
  }

  @HostListener('wheel', ['$event'])
  onWheel(event: any): void {
    this.mouseWheelFunc(event);
  }

  @HostListener('onmousewheel', ['$event'])
  onMouseWheelIE(event: any): void {
    this.mouseWheelFunc(event);
  }

  mouseWheelFunc(event: any): void {
    if (event.stopPropagation) {
      event.stopPropagation();
    }
  }
}
