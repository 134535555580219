import { DocumentsShareExtension, EditorComponent, EditorEvent, Nx3Api, ReferenceListEvent, fromToDate } from '@nx3/nx3-core-ui';

export class CustomDocumentsshareExtension extends DocumentsShareExtension {
  constructor(public nx3: Nx3Api) {
    super(nx3);
  }

  onReferenceListInit(event: ReferenceListEvent): void {
    super.onReferenceListInit(event);
    event.component.config.filter.hiddenFilterFields = ['confirmation'];
  }

  onEditorAfterView(event: EditorEvent): void {
    this.createDateFieldValidator(event.component, 'validFrom', 'validTo');
  }

  createDateFieldValidator(component: EditorComponent, fromDate: string, toDate: string) {
    const validFromField = component.form.get(fromDate);
    const validToField = component.form.get(toDate);

    validFromField.setValidators([
      validFromField.validator,
      fromToDate(validFromField, validToField),
    ]);
    validToField.setValidators([
      validToField.validator,
      fromToDate(validFromField, validToField),
    ]);
  }

}

