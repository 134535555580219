import { ListConfig, Nx3Api, QualificationExtension, ReferenceListConfig } from '@nx3/nx3-core-ui';
export class CustomQualificationExtension extends QualificationExtension {
  constructor(public nx3: Nx3Api) {
    super(nx3);
  }
  configureList(config: ListConfig): void {
    super.configureList(config);
    config.enableListSettings = false;
  }

  configureReferenceLists(referenceListConfig: Map<string, ReferenceListConfig>) {
    const config = new ReferenceListConfig();
    config.table.toolDelete = false;
    config.table.toolEdit = false;
    config.enableCreate = false;
    referenceListConfig.set('nx3document', config);
  }
}
