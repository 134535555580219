import { Component, OnInit } from '@angular/core';
import {
  AbstractCollectionFieldWidgetComponent,
  ExtensionService,
  Nx3Api,
} from '@nx3/nx3-core-ui';

@Component({
  selector: 'app-choose-partnernumber',
  templateUrl: './choose-partnernumber.component.html',
  styleUrls: ['./choose-partnernumber.component.scss'],
})
export class ChoosePartnernumberComponent
  extends AbstractCollectionFieldWidgetComponent
  implements OnInit
{
  createValue() {
    throw new Error('Method not implemented.');
  }
  addValue(value: any, index: number): void {
    throw new Error('Method not implemented.');
  }
  cloneValue(value: any) {
    throw new Error('Method not implemented.');
  }

  constructor(public nx3: Nx3Api, protected extensions: ExtensionService) {
    super(nx3);
  }

  ngOnInit(): void {
    this.object = this.getValue();
  }

  chooseAndComplete(partnernumber: number) {
    this.nx3.client.events.fireEvent('CHOOSE_PARTNERNUMBER', {
      partnernumber: partnernumber,
    });
  }
}
