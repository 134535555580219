<div class="row">
  <div class="col-3" *ngFor="let field of fields">
    <nx3-form-field
      [form]="form"
      [object]="object"
      [metadata]="metadata"
      [field]="field"
      [resourceConfig]="resourceConfig"
    ></nx3-form-field>
  </div>
</div>

<button *ngIf="!object.id || object.id === -1" class="btn btn-success btn-sm" (click)="addRelation()">
  {{ 'nx3_button_add_relation' | translate }}
</button>
