import {
  ButtonModel,
  DetailComponent,
  EditorConfig,
  EditorEvent,
  FormBehavior,
  ListComponent,
  ModalComponent,
  Nx3Api,
  Nx3UserTaskExtension,
} from '@nx3/nx3-core-ui';

import { BehaviorSubject, Subject } from 'rxjs';

export abstract class DecisionFieldUserTaskExtension extends Nx3UserTaskExtension {
  static completeDisabled: Subject<boolean> = new BehaviorSubject<boolean>(
    true
  );

  constructor(
    public nx3: Nx3Api,
    public decisionField: string,
    resourceName: string
  ) {
    super(nx3, resourceName);
  }

  onEditorDestroy(event: EditorEvent): void {
    super.onEditorDestroy(event);
    DecisionFieldUserTaskExtension.completeDisabled.next(true);
  }

  configureEditor(config: EditorConfig): void {
    super.configureEditor(config);
    config.form.formBehavior = (behavior: FormBehavior) => {
      behavior
        .when(this.decisionField, (object: any) => {
          return true;
        })
        .run((object) => {
          DecisionFieldUserTaskExtension.completeDisabled.next(
            !object[this.decisionField]
          );
        });
    };
  }

  configureModal(
    list: ListComponent,
    detail: DetailComponent,
    modalInstance: ModalComponent
  ): void {
    super.configureModal(list, detail, modalInstance);

    const completeButton: ButtonModel = modalInstance.buttons.find(
      (btn) => btn.id === 'complete_task'
    );
    completeButton.withDisabled(
      DecisionFieldUserTaskExtension.completeDisabled
    );
  }
}
