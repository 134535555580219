import { Component, OnInit } from '@angular/core';
import { EntityReference, Nx3Entity, ResourceConfig } from '@nx3/nx3-client';
import { Nx3Api } from '@nx3/nx3-core-ui';
import { take } from 'rxjs/operators';

@Component({
  selector: 'lib-department-contact-info',
  templateUrl: './department-contact-info.component.html',
  styleUrls: ['./department-contact-info.component.scss'],
})
export class DepartmentContactInfoComponent implements OnInit {
  department: Nx3Entity;
  resourceConfig: ResourceConfig;
  error: string;
  constructor(private nx3: Nx3Api) {}

  ngOnInit(): void {
    this.nx3.client
      .getPersonAndOrganization(this.nx3.config.registrationServer)
      .subscribe((personAndOrganization) => {
        const departmentReference: EntityReference =
          personAndOrganization.organization.department;
        this.resourceConfig = ResourceConfig.forClass(
          this.nx3.config.registrationServer,
          departmentReference.type
        );
        this.nx3.client.entity
          .findOne(this.resourceConfig, departmentReference.referenceId)
          .pipe(take(1))
          .subscribe(
            (department: Nx3Entity) => {
              this.department = department;
            },
            (error: any) => {
              this.error = 'department_changed_error';
            }
          );
      });
  }

  logout() {
    this.nx3.navigation.logout();
  }
}
