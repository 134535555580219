import {
  ContentPanelConfig,
  DetailConfig,
  EntityComponentModel,
  FormlayoutComponent,
  GeneralInformationComponent,
  nestedConfig,
  Nx3Api,
  preset,
} from '@nx3/nx3-core-ui';
import { of } from 'rxjs';
import { BusinesspartnerCarrierRelationComponent } from '../components/businesspartner-carrierrelation/businesspartner-carrierrelation.component';
import { CarrierCandidateTransformationComponent } from '../components/carriercandidate-transformation/carriercandidate-transformation.component';
import { DefaultExtension } from './default-extension';

export class CarrierCandidatesTransfBatchExtension extends DefaultExtension {
  constructor(public nx3: Nx3Api) {
    super(nx3, 'carriercandidatestransfbatch');
  }

  configureDetail(config: DetailConfig): void {
    const presets = [];

    presets.push(
      preset(
        FormlayoutComponent,
        nestedConfig(false)
          .mapModel('entity', 'object')
          .withExists((model: EntityComponentModel) => {
            return of(
              GeneralInformationComponent.getDisplayedFields(
                model.metadata,
                model.entity
              ).length === 0
            );
          })
      )
    );

    presets.push(
      preset(
        CarrierCandidateTransformationComponent,
        nestedConfig(false).withContentPanel(
          new ContentPanelConfig('candidates', 'carriercandidate_list')
        ),
        {}
      )
    );

    presets.push(
      preset(
        BusinesspartnerCarrierRelationComponent,
        nestedConfig(false)
          .withContentPanel(new ContentPanelConfig('users', 'carrier_list'))
          .withExists((model: EntityComponentModel) => {
            return of(model.entity.actor?.hasOwnProperty('legalForm'));
          }),
        {}
      )
    );

    config.layout.rootLayout().row().componentPresets(presets);
  }
}
