import {
  DetailConfig,
  EditorComponent,
  EditorEvent,
  ModalComponent,
  Nx3Api,
  Nx3UserTaskExtension,
  newConfig,
} from '@nx3/nx3-core-ui';
import { CarrierReferenceGerneralInformationComponent } from '../components/carrier-reference-gerneral-information/carrier-reference-gerneral-information.component';
import { SanctionListCheckInformationComponent } from '../components/sanction-list-check-information/sanction-list-check-information.component';

export class SanctionListCheckUserTaskExtension extends Nx3UserTaskExtension {
  modalInstance: ModalComponent;

  constructor(public nx3: Nx3Api, resourceName = 'sanctionlistcheckusertask') {
    super(nx3, resourceName);
  }

  configureDetail(config: DetailConfig) {
    config.enablePlaces = false;
    config.windowTitle = false;
    config.header.visible = false;
    config.layout
      .rootLayout()
      .withUseContainer(false)
      .withPadding(2, 0)
      .row()
      .component(EditorComponent, newConfig(12))
      .up();
  }

  onEditorInit(event: EditorEvent): void {
    event.component.metadata.getFieldByName('carrierId').componentType =
      CarrierReferenceGerneralInformationComponent;
    event.component.metadata.getFieldByName('carrierId').labelKey =
      'carrier_label';
    event.component.metadata.getFieldByName('choice').labelKey =
      'sanction_carrier_label';
    event.component.metadata.getFieldByName(
      'sanctionListCheckResult'
    ).componentType = SanctionListCheckInformationComponent;
  }
}
