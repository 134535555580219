import { DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import {
  Nx3CmsModule,
  Nx3ComponentsModule,
  Nx3QualificationModule,
} from '@nx3/nx3-core-ui';
import { HighchartsChartModule } from 'highcharts-angular';
import { DepartmentContactInfoComponent } from './department-contact-info/department-contact-info.component';
import { NutsRegionsComponent } from './nuts-regions/nuts-regions.component';
import { WarnAboutCarrierResetModalComponent } from './warn-about-carrier-reset-modal/warn-about-carrier-reset-modal.component';
import { MultipleRelationsComponent } from './multiple-relations/multiple-relations.component';
import { HiddenNutsRegionsComponent } from './hidden-nuts-regions/hidden-nuts-regions.component';
import { SelectRegionFieldComponent } from './select-region-field/select-region-field.component';

@NgModule({
  providers: [DatePipe],
  declarations: [
    DepartmentContactInfoComponent,
    HiddenNutsRegionsComponent,
    MultipleRelationsComponent,
    NutsRegionsComponent,
    SelectRegionFieldComponent,
    WarnAboutCarrierResetModalComponent,
  ],
  imports: [
    Nx3ComponentsModule,
    Nx3CmsModule,
    Nx3QualificationModule,
    HighchartsChartModule,
    TypeaheadModule
  ],
  exports: [],
})
export class SharedModule {}
