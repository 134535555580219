import { Nx3Entity } from '@nx3/nx3-client';
import {
  ButtonModel,
  ButtonRegistration,
  CommonNames,
  ConfirmDialogCommand,
  DetailConfig,
  DetailEvent,
  DropzoneEventType,
  EditorConfig,
  EditorEvent,
  FieldEvent,
  Nx3Api,
  StateInstanceComponent,
  VerificationHistoryComponent,
} from '@nx3/nx3-core-ui';
import { BehaviorSubject, take } from 'rxjs';
import { AbstractPortalExtension } from '../../../../portal/src/app/resource-extensions/abstract-portal-extension';

export class VehicleExtension extends AbstractPortalExtension {
  constructor(public nx3: Nx3Api) {
    super('vehicle', nx3);
  }

  onBeforeSave(entity: Nx3Entity): void {
    entity.application = entity.application.filter((application) => application);
    entity.vehicleOperationsTypes = entity.vehicleOperationsTypes.filter((type) => type);
  }

  onDetailAfterView(event: DetailEvent): void {
    this.hideVehicleLicenseFields(event.component.metadata, false);
  }

  configureDetail(config: DetailConfig): void {
    config.disabledComponents.push(StateInstanceComponent, VerificationHistoryComponent);
  }

  configureEditor(config: EditorConfig) {
    this.nx3.client.events.on(
      DropzoneEventType.FILE_SELECTED,
      (event) => {
        if (this.editorInstance) {
          this.editorInstance.form.get('vehicleLicense').get('name').setValue(event.data.fileName);
        }
      },
      this
    );

    this.nx3.client.events.on(
      DropzoneEventType.FILE_REMOVED,
      (event) => {
        if (this.editorInstance) {
          this.editorInstance.form.get('vehicleLicense').get('name').setValue(null);
        }
      },
      this
    );
  }

  onDetailInit(event: DetailEvent): void {
    if (!this.nx3.modal.modals.hasOpenModals()) {
      this.detailInstance.registerButton(
        new ButtonRegistration(
          this.createButtonWithAction('deactivate')
            .withOverflow(true)
            .withIcon('times')
            .withTooltip('nx3_button_deactivate')
            .withExists(
              new BehaviorSubject(
                event.component.entity.state.key !== 'DEACTIVATED' &&
                  this.nx3.client.auth.hasResourceAuthority(this.detailInstance.resourceModel.authority, 'UPDATE')
              )
            ),
          DetailConfig.BUTTON_GROUP_TOP
        )
      );

      this.detailInstance.registerButton(
        new ButtonRegistration(
          this.createButtonWithAction('reactivate')
            .withOverflow(true)
            .withIcon('check')
            .withTooltip('nx3_button_reactivate')
            .withExists(
              new BehaviorSubject(
                event.component.entity.state.key === 'DEACTIVATED' &&
                  this.nx3.client.auth.hasResourceAuthority(this.detailInstance.resourceModel.authority, 'UPDATE')
              )
            ),
          DetailConfig.BUTTON_GROUP_TOP
        )
      );
    }
  }

  onEditorDestroy(event: EditorEvent): void {
    this.nx3.client.events.unregister(DropzoneEventType.FILE_SELECTED, this);
    this.nx3.client.events.unregister(DropzoneEventType.FILE_REMOVED, this);
  }

  onEditorAfterView(event: EditorEvent): void {
    if (event.component.entity.vehicleLicensePresent) {
      event.component.metadata.getFieldByName('vehicleLicensePresent').hidden = true;
    } else {
      this.hideVehicleLicenseFields(event.component.metadata, true);
      event.component.form.get('vehicleLicense').disable();
    }
  }

  onFieldValueChange(event: FieldEvent) {
    if (event.field.name === 'vehicleLicensePresent') {
      if (event.object.vehicleLicensePresent) {
        this.hideVehicleLicenseFields(this.editorInstance.metadata, false);
        this.editorInstance.form.get('vehicleLicense').enable();
      } else {
        this.hideVehicleLicenseFields(this.editorInstance.metadata, true);
        this.editorInstance.form.get('vehicleLicense').disable();
      }
    }
  }

  hideVehicleLicenseFields(metadata, hide: boolean): void {
    metadata.getFieldByName('vehicleLicense.content').hidden = hide;
    metadata.getFieldByName('vehicleLicense.name').hidden = hide;
    metadata.getFieldByName('vehicleLicense.language').hidden = hide;
    metadata.getFieldByName('vehicleLicense.description').hidden = hide;
  }

  createButtonWithAction(action: string): ButtonModel {
    return new ButtonModel('nx3_button_' + action + '_vehicle', CommonNames.DETAIL, (event: any) => {
      this.nx3.modal.confirm(
        new ConfirmDialogCommand('nx3_button_' + action, 'nx3_message_' + action + '_vehicle', () => {
          this.nx3.client.rest
            .post(this.detailInstance.resourceConfig.server, '/api/vehicle/' + action + '/' + this.detailInstance.entity.id, {})
            .pipe(take(1))
            .subscribe(() => {
              this.nx3.toast.success(this.nx3.i18n.translate.instant('nx3_message_' + action + '_vehicle_success'));
              this.nx3.router.navigate([
                '',
                CommonNames.LIST,
                this.detailInstance.resourceConfig.server,
                this.detailInstance.resourceConfig.resourceName,
              ]);
            });
        })
      );
    });
  }
}
