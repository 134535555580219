import { Component, OnInit } from '@angular/core';
import {
  Nx3BeanModel,
  Nx3SearchFilter,
  ResourceConfig,
  ResourceModel,
} from '@nx3/nx3-client';
import {
  AbstractEntityComponent,
  DeleteConfig,
  EntitySelectionCommand,
  ExtensionService,
  ListConfig,
  Nx3Api,
} from '@nx3/nx3-core-ui';
import { forkJoin } from 'rxjs';
import { take } from 'rxjs/operators';
@Component({
  selector: 'nx3-businesspartner-carrierrelation',
  templateUrl: './businesspartner-carrierrelation.component.html',
  styleUrls: ['./businesspartner-carrierrelation.component.scss'],
})
export class BusinesspartnerCarrierRelationComponent
  extends AbstractEntityComponent
  implements OnInit
{
  carrierResourceConfig: ResourceConfig;
  carrierBeanModel: Nx3BeanModel;
  carrierResourceModel: ResourceModel;
  carrierSpecificationMetadata: Nx3BeanModel;
  filter: Nx3SearchFilter;
  listConfig: ListConfig = new ListConfig();
  deleteConfig: DeleteConfig = new DeleteConfig();
  initialized = false;

  constructor(public nx3: Nx3Api, protected extensions: ExtensionService) {
    super(nx3, extensions);
  }

  configureList() {
    this.listConfig.setFixedView(false);
    this.listConfig.setStickyLayout(false);
    this.listConfig.enableFilterModeToggle = false;
    this.listConfig.enableListSettings = false;
    this.listConfig.table.toolSelection = false;
    this.listConfig.openInModal = true;
    this.listConfig.header.visible = false;
    this.listConfig.header.sticky = false;
  }

  ngOnInit(): void {
    this.configureList();
    const observables = [];

    observables.push(
      this.nx3.client.resource.resourceModelByName(
        this.resourceConfig.server,
        'carrier'
      )
    );

    forkJoin(observables)
      .pipe(take(1))
      .subscribe((data) => {
        this.carrierResourceModel = data[0] as any;
        this.carrierResourceConfig = ResourceConfig.forResourceModel(
          this.resourceConfig.server,
          this.carrierResourceModel
        );

        const innerObservables = [];
        innerObservables.push(
          this.nx3.client.metadata.get(
            this.resourceConfig.server,
            this.carrierResourceModel
          )
        );
        innerObservables.push(
          this.nx3.client.entity.specificationMetadata(
            this.carrierResourceConfig
          )
        );
        innerObservables.push(
          this.nx3.client.entity.getFilter(this.carrierResourceConfig, true)
        );

        forkJoin(innerObservables)
          .pipe(take(1))
          .subscribe((innerData: any[]) => {
            this.carrierBeanModel = innerData[0] as any;
            this.carrierSpecificationMetadata = innerData[1] as any;
            this.filter = innerData[2] as any;
            this.filter.contextStore =
              EntitySelectionCommand.createSelectionContext(
                this.metadata,
                undefined,
                this.entity.id,
                undefined,
                'departmentCarriers'
              );
            this.initialized = true;
          });
      });
  }
}
