import { DetailConfig, ListConfig, Nx3ImageExtension } from '@nx3/nx3-core-ui';

export class CustomImageExtension extends Nx3ImageExtension  {

  configureDetail(config: DetailConfig): void {
    super.configureDetail(config);
    config.enableDuplicate = false;
  }

  configureList(config: ListConfig): void {
    super.configureList(config);
    config.enableDuplicate = false;
  }

}
