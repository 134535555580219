import {
  ButtonModel,
  ButtonRegistration,
  DetailConfig,
  DetailEvent,
  Nx3Api,
  QuestionnaireExtension
} from '@nx3/nx3-core-ui';
import { take, BehaviorSubject } from 'rxjs';

export class CustomQuestionnaireExtension extends QuestionnaireExtension {
  constructor(public nx3: Nx3Api) {
    super(nx3);
  }

  onDetailInit(event: DetailEvent): void {
    event.component.getButtonById('top', 'editor').exists =
      !event.component.entity.state ||
      event.component.entity.state.key == 'DESIGN';
    event.component.getButtonById('top', 'delete').exists =
      event.component.entity.state?.key != 'ACTIVE';

    event.component.registerButton(
      new ButtonRegistration(
        new ButtonModel(
          'nx3_button_activate_questionnaire',
          'activateQuestionnaire',
          () => {
            this.nx3.spinner.show();
            this.nx3.client.rest
              .post(
                event.component.resourceConfig.server,
                '/application/statemachine/state/transist/' +
                  event.component.metadata.type +
                  '/' +
                  event.component.entity.id,
                'ACTIVE'
              )
              .pipe(take(1))
              .subscribe(() => {
                this.nx3.navigation.reload();
                this.nx3.spinner.hide();
              });
          }
        )
          .withOverflow(false)
          .withIcon('folder-open')
          .withTooltip('nx3_button_activate_questionnaire')
          .withExists(
            new BehaviorSubject(event.component.entity.state?.key == 'DESIGN')
          ),
        DetailConfig.BUTTON_GROUP_TOP
      )
    );

    event.component.registerButton(
      new ButtonRegistration(
        new ButtonModel(
          'nx3_button_retire_questionnaire',
          'retireQuestionnaire',
          () => {
            this.nx3.spinner.show();
            this.nx3.client.rest
              .post(
                event.component.resourceConfig.server,
                '/application/statemachine/state/transist/' +
                  event.component.metadata.type +
                  '/' +
                  event.component.entity.id,
                'RETIRED'
              )
              .pipe(take(1))
              .subscribe(() => {
                this.nx3.navigation.reload();
                this.nx3.spinner.hide();
              });
          }
        )
          .withOverflow(false)
          .withIcon('trash-alt')
          .withTooltip('nx3_button_retire_questionnaire')
          .withExists(
            new BehaviorSubject(event.component.entity.state?.key == 'ACTIVE')
          ),
        DetailConfig.BUTTON_GROUP_TOP
      )
    );
  }

}
