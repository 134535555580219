import { Nx3Api } from '@nx3/nx3-core-ui';

import { DecisionFieldUserTaskExtension } from './decision-field-user-task-extension';

export class EnvironmentalManagementUserTaskExtension extends DecisionFieldUserTaskExtension {
  constructor(public nx3: Nx3Api) {
    super(
      nx3,
      'environmentalManagementAccepted',
      'environmentalmanagementusertask'
    );
  }
}
