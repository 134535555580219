import { ApplicationEvent, Nx3BeanModel, Nx3Entity, Nx3SearchFilter, ResourceConfig, ResourceModel } from '@nx3/nx3-client';
import {
  AddressMarkerComponent,
  AlertDialogCommand,
  ButtonModel,
  ButtonRegistration,
  CommonNames,
  ComponentGridLayout,
  ConfirmDialogCommand,
  ContentPanelConfig,
  DetailComponent,
  DetailConfig,
  DetailEvent,
  EditorConfig,
  EditorEvent,
  EntityComponentModel,
  EntitySelectionCommand,
  FormBehavior,
  FormlayoutComponent,
  GridComponentConfig,
  ListAdapterComponent,
  ListConfig,
  ListEvent,
  Nx3Api,
  ReferenceListComponent,
  ReferenceListConfig,
  ReferenceListEvent,
  ReferenceListsComponent,
  SelectionType,
  newConfig,
} from '@nx3/nx3-core-ui';
import { CarrierService } from 'projects/shared/src/lib/carrier.service';
import { NutsRegionsComponent } from 'projects/shared/src/lib/nuts-regions/nuts-regions.component';
import { BehaviorSubject, of } from 'rxjs';
import { take } from 'rxjs/operators';
import { MessageQueueInformationsComponent } from '../components/message-queue-informations/message-queue-informations.component';
import { DefaultExtension } from './default-extension';

export class CarrierExtension extends DefaultExtension {
  carrierService: CarrierService;
  hideReferenceListNames = ['platformagreementsconsent'];
  ignoreTenderContext = false;
  documentLayoutInitialized = false;
  fleetLayoutInitialized = false;
  regionRelationLayoutInitialized = false;

  constructor(public nx3: Nx3Api) {
    super(nx3, 'carrier');
    this.carrierService = this.nx3.injector.get(CarrierService);
  }

  onBeforeSearch(filter: Nx3SearchFilter): void {
    if (this.nx3.getCurrentRouteComponent().resourceName === 'tender' && !this.ignoreTenderContext) {
      filter.contextStore = {
        declaringEntityId: this.nx3.getCurrentRouteComponent().entity.id,
        declaringClass: this.nx3.getCurrentRouteComponent().entity.resourceType,
      };
    } else {
      filter.contextStore = {};
    }
  }

  onListInit(event: ListEvent): void {
    const parentResourceName = this.nx3.getCurrentRouteComponent().resourceName;

    if (parentResourceName === 'documentsshare' || parentResourceName === 'tender') {
      event.component.reset();
    }
    if (parentResourceName === 'tender' && !this.ignoreTenderContext) {
      const tender = this.nx3.getCurrentRouteComponent().entity;

      event.component.config.table.toolSelection = false;
      event.component.config.table.toolEdit = false;
      event.component.config.enableFilterModeToggle = false;
      event.component.config.enableListSettings = false;

      if (tender.immutable) {
        event.component.config.table.toolDelete = false;
      } else {
        event.component.config.table.toolDelete = true;
        event.component.registerButton(
          new ButtonRegistration(
            new ButtonModel('nx3_button_select', 'select_carrier', () => {
              this.chooseCadidateFilter();
            })
              .withIcon('share')
              .withExists(new BehaviorSubject(!tender.immutable))
              .withTooltip('nx3_button_select'),
            ListConfig.BUTTON_GROUP_FILTER
          )
        );
      }

      event.component.deleteOne = (object: Nx3Entity) => {
        this.nx3.client.rest
          .post(
            event.component.resourceConfig.server,
            `/api/tender/${this.nx3.getCurrentRouteComponent().entity.id}/removecarrier/${object.id}`,
            {}
          )
          .pipe(take(1))
          .subscribe(() => {
            event.component.search();
          });
      };
    }
  }

  onListDestroy(event: ListEvent): void {
    this.ignoreTenderContext = false;
  }

  chooseCadidateFilter() {
    this.ignoreTenderContext = true;
    this.nx3.client.resource
      .resourceModelByName(this.nx3.config.registrationServer, 'carrier')
      .pipe(take(1))
      .subscribe((carrierResourceModel: ResourceModel) => {
        const carrierResourceConfig = ResourceConfig.forResourceModel(this.nx3.config.registrationServer, carrierResourceModel);
        this.nx3.modal.selectEntity(
          new EntitySelectionCommand(
            carrierResourceConfig,
            carrierResourceModel,
            SelectionType.MULTI,
            (selection: Nx3Entity[]) => {
              selection;
              this.nx3.spinner.show();

              this.nx3.client.rest
                .post(
                  carrierResourceConfig.server,
                  `/api/tender/${this.nx3.getCurrentRouteComponent().entity.id}/assigntocarrier`,
                  selection.map((item) => item.id)
                )
                .pipe(take(1))
                .subscribe(() => {
                  this.nx3.spinner.hide();
                  this.nx3.navigation.reload();
                });
            }
          )
        );
      });
  }

  onEditorDestroy(event: EditorEvent): void {
    this.carrierService.resetState();
  }

  onEditorInit(event: EditorEvent): void {
    this.carrierService.initEditor(event.component);
  }

  onEditorAfterView(event: EditorEvent): void {
    this.carrierService.editorRendered(event.component);
  }

  configureEditor(config: EditorConfig): void {
    super.configureEditor(config);
    config.form.formBehavior = (behavior: FormBehavior) => {
      this.carrierService.configureFormBehavior(behavior, this.editorInstance);
    };
  }

  configureDetail(config: DetailConfig): void {
    config.enableDuplicate = false;
    config.disabledComponents = [AddressMarkerComponent, ReferenceListsComponent];

    this.detailLayout().createDefaultLayout(this.nx3, config, config.layout.rootLayout());

    config.layout.addLayout(new ComponentGridLayout(undefined, 'fleet_label', 'truck-loading').withUseContainer(false));

    config.layout.addLayout(new ComponentGridLayout(undefined, 'document_list', 'file').withUseContainer(false));

    config.layout.addLayout(new ComponentGridLayout(undefined, 'regionrelation_list', 'road').withUseContainer(false));

    this.nx3.client.auth
      .isAuthenticated()
      .pipe(take(1))
      .subscribe((isAuthenticated) => {
        if (isAuthenticated) {
          (config.layout.rootLayout().rows[1].columns[1].content as ComponentGridLayout).rows.push(
            (config.layout.rootLayout().rows[1].columns[0].content as ComponentGridLayout).rows[2]
          );
          (config.layout.rootLayout().rows[1].columns[1].content as ComponentGridLayout).rows.push(
            (config.layout.rootLayout().rows[1].columns[0].content as ComponentGridLayout).rows[3]
          );
          (config.layout.rootLayout().rows[1].columns[1].content as ComponentGridLayout).rows.push(
            (config.layout.rootLayout().rows[1].columns[0].content as ComponentGridLayout).rows[4]
          );
          (config.layout.rootLayout().rows[1].columns[1].content as ComponentGridLayout).rows.push(
            (config.layout.rootLayout().rows[1].columns[0].content as ComponentGridLayout).rows[5]
          );
          (config.layout.rootLayout().rows[1].columns[1].content as ComponentGridLayout).rows.push(
            (config.layout.rootLayout().rows[1].columns[0].content as ComponentGridLayout).rows[6]
          );
          (config.layout.rootLayout().rows[1].columns[0].content as ComponentGridLayout).rows.splice(2, 5);
          config.layout.rootLayout().position(ListAdapterComponent, 2);
        }
      });

    if (this.nx3.client.auth.hasSecurityGroup('NX3_GROUP')) {
      config.layout
        .rootLayout()
        .rows[1].columns[1].asLayout()
        .component(
          MessageQueueInformationsComponent,
          newConfig(12, true)
            .withContentPanel(
              new ContentPanelConfig('signal-bars', 'transa_label_trias_state', 'p-2').setValue('collapsible', false)
            )
            .withExists((model: EntityComponentModel) => {
              return of(true);
            })
        );
    }
  }

  onDetailDestroy(event: DetailEvent): void {
    this.nx3.client.events.unregister('LAYOUT_SELECTED', this);

    this.documentLayoutInitialized = false;
    this.fleetLayoutInitialized = false;
    this.regionRelationLayoutInitialized = false;
    if (event.component.config.layout.layouts[1].rows.length > 0) {
      event.component.config.layout.layouts[1].rows = [];
    }
    if (event.component.config.layout.layouts[2].rows.length > 0) {
      event.component.config.layout.layouts[2].rows = [];
    }
    if (event.component.config.layout.layouts[3].rows.length > 0) {
      event.component.config.layout.layouts[3].rows = [];
    }
  }

  onDetailInit(event: DetailEvent): void {
    super.onDetailInit(event);
    const createButton = this.detailInstance.getButtonById('top', 'editor');
    const createExists = createButton ? createButton.exists : false;
    if (event.component.metadata.form.getSectionsByKey('s11')) {
      event.component.metadata.form.getSectionsByKey('s11').collapsed = true;
    }
    this.nx3.client.events.on(
      'LAYOUT_SELECTED',
      (ae: ApplicationEvent) => {
        if (createExists) {
          if (ae.data.labelKey == 'fleet_label') {
            this.detailInstance.getButtonById(DetailConfig.BUTTON_GROUP_TOP, CommonNames.EDITOR).exists = false;
            this.initFleet(ae.data, event.component);
          } else if (ae.data.labelKey == 'regionrelation_list') {
            this.detailInstance.getButtonById(DetailConfig.BUTTON_GROUP_TOP, CommonNames.EDITOR).exists = false;
            this.initRelations(ae.data);
          } else if (ae.data.labelKey == 'document_list') {
            this.detailInstance.getButtonById(DetailConfig.BUTTON_GROUP_TOP, CommonNames.EDITOR).exists = false;
            this.initDocuments(ae.data);
          } else {
            this.detailInstance.getButtonById(DetailConfig.BUTTON_GROUP_TOP, CommonNames.EDITOR).exists = true;
          }
        }
      },
      this
    );

    event.component.registerButton(this.createResetCarrierButton(event));
  }

  createResetCarrierButton(event: DetailEvent): ButtonRegistration {
    return new ButtonRegistration(
      new ButtonModel('nx3_button_reset_partner_number', 'reset_partner_number', () => {
        this.nx3.modal.confirm(
          new ConfirmDialogCommand('nx3_button_reset_partner_number', 'nx3_message_reset_partner_number_confirm', () => {
            this.nx3.spinner.show();

            this.nx3.client.rest
              .get(event.component.resourceConfig.server, '/api/carrier/resetpartnernumber/' + event.component.entity.id)
              .pipe(take(1))
              .subscribe(
                () => {
                  this.nx3.spinner.hide();
                  this.nx3.navigation.reload();
                },
                (error: any) => {
                  this.nx3.spinner.hide();
                  const command = new AlertDialogCommand(
                    'nx3_label_error',
                    error.response.data.messageKey,
                    'ban',
                    'nx3-color-danger nx3-text-extra-large',
                    null,
                    null
                  );
                  this.nx3.client.events.fire(command);
                }
              );
          })
        );
      })
        .withOverflow(false)
        .withIcon('shuffle')
        .withExists(
          new BehaviorSubject(
            event.component.entity.virtual?.lifecycle?.level !== -1 &&
              event.component.entity.partnerNr &&
              this.nx3.client.auth.hasSecurityGroup('CARRIER_ADMINISTRATOR_GROUP') &&
              this.nx3.client.businesspartner.getOrganizationInstant(this.nx3.config.registrationServer).id == '1001'
          )
        )
        .withCssClass(this.nx3.config.getButtonClass('primary'))
        .withTooltip('nx3_button_reset_partner_number'),
      DetailConfig.BUTTON_GROUP_TOP
    );
  }

  initFleet(layout: ComponentGridLayout, detail: DetailComponent) {
    if (layout.labelKey !== 'fleet_label' || this.fleetLayoutInitialized) {
      return;
    }
    this.nx3.spinner.show();

    const server = detail.resourceConfig.server;
    const carrierUrl = detail.resourceModel.url;
    this.nx3.client.rest
      .get(server, carrierUrl + '/fleet/' + detail.entity.id)
      .pipe(take(1))
      .subscribe((entity: Nx3Entity) => {
        const fleet = entity;
        const fleetResourceConfig = ResourceConfig.forClass(server, fleet.resourceType);
        this.nx3.client.resource
          .resourceModelByConfig(fleetResourceConfig)
          .pipe(take(1))
          .subscribe((fleetResourceModel: ResourceModel) => {
            this.nx3.client.metadata
              .get(server, fleetResourceModel)
              .pipe(take(1))
              .subscribe((fleetMetadata: Nx3BeanModel) => {
                layout.row().component(FormlayoutComponent, new GridComponentConfig(12), {
                  metadata: fleetMetadata,
                  object: fleet,
                  resourceConfig: fleetResourceConfig,
                });
                fleetMetadata.getFieldByName('carrier').hiddenDetail = true;
                this.nx3.spinner.hide();
              });
          });
      });

    this.fleetLayoutInitialized = true;
  }

  initRelations(layout: ComponentGridLayout) {
    if (layout.labelKey !== 'regionrelation_list' || this.regionRelationLayoutInitialized) {
      return;
    }
    const parentModel = new EntityComponentModel(
      this.detailInstance.resourceModel,
      this.detailInstance.resourceConfig,
      this.detailInstance.metadata,
      this.detailInstance.entity
    );

    const regionRelationModel = this.detailInstance.referencingModels.find((r) => r.resourceModel.name === 'regionrelation');

    layout
      .row()
      .component(ReferenceListComponent, new GridComponentConfig(12), {
        parentModel: parentModel,
        referenceModel: regionRelationModel,
      })
      .up()
      .row()
      .component(NutsRegionsComponent, new GridComponentConfig(12), {});

    this.regionRelationLayoutInitialized = true;
  }

  initDocuments(layout: ComponentGridLayout) {
    if (layout.labelKey !== 'document_list' || this.documentLayoutInitialized) {
      return;
    }
    const parentModel = new EntityComponentModel(
      this.detailInstance.resourceModel,
      this.detailInstance.resourceConfig,
      this.detailInstance.metadata,
      this.detailInstance.entity
    );

    const documentModel = this.detailInstance.referencingModels.find((r) => r.resourceModel.name === 'nx3document');

    layout.row().component(ReferenceListComponent, new GridComponentConfig(12), {
      parentModel: parentModel,
      referenceModel: documentModel,
    });

    this.documentLayoutInitialized = true;
  }

  configureList(config: ListConfig): void {
    config.enableDuplicate = false;
    config.enableGuidedTour = false;
    config.enableListSettings = false;
    config.routeImport = (resourceConfig: ResourceConfig) => {
      return [CommonNames.IMPORTER, resourceConfig.server, 'carriercandidate'];
    };
  }

  configureReferenceLists(referenceListConfig: Map<string, ReferenceListConfig>) {
    const config = new ReferenceListConfig();
    config.table.toolDelete = false;
    config.table.toolEdit = false;
    config.enableCreate = false;
    referenceListConfig.set('nx3document', config);
  }

  onReferenceListAfterSearch(event: ReferenceListEvent): void {
    if (event.component.metadata.name === 'RegionRelation') {
      this.nx3.client.events.fireEvent('relationsLoaded', {
        relations: event.data.page.content,
      });
    }
  }
}
