import {
    DetailEvent,
    Nx3Api,
    VerificationDefinitionExtension
} from '@nx3/nx3-core-ui';

export class CustomVerificationDefinitionExtension extends VerificationDefinitionExtension {

  constructor(public nx3: Nx3Api) {
    super(nx3);
  }

  onDetailInit(event: DetailEvent): void {
    event.component.config.layout.rootLayout().rows[1].columns[0].config.width = 12;
  }

}
