import { AlertDialogCommand, ButtonModel, ButtonRegistration, ConfirmDialogCommand, DetailConfig, DetailEvent, Nx3Api } from '@nx3/nx3-core-ui';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { DefaultExtension } from './default-extension';

export class CarrierInvitationExtension extends DefaultExtension {

  constructor(public nx3: Nx3Api) {
    super(nx3, 'carrierinvitation');
  }

  onDetailInit(event: DetailEvent): void {
    super.onDetailInit(event);
    const inviteButtonName : string = event.component.entity.state.key === 'NEW' ? 'nx3_button_invite_carrier' : 'nx3_button_reinvite_carrier';

    this.detailInstance.registerButton(
      new ButtonRegistration(
        new ButtonModel(inviteButtonName, undefined, () => {
          this.nx3.modal.confirm(
            new ConfirmDialogCommand(
              event.component.entity.state.key === 'NEW' ? 'nx3_button_invite_carrier' : 'nx3_button_reinvite_carrier',
              event.component.entity.state.key === 'NEW' ? 'nx3_message_invite_carrier_confirm' : 'nx3_message_reinvite_carrier_confirm',
              () => {
                this.nx3.spinner.show();
                this.nx3.client.rest
                  .post(
                    event.component.resourceConfig.server,
                    '/api/carrierinvitation/invite/' + event.component.entity.id,
                    {}
                  )
                  .pipe(take(1))
                  .subscribe(
                    () => {
                      this.nx3.spinner.hide();
                      this.nx3.navigation.reload();
                      const message = this.nx3.i18n.translate.instant(
                        event.component.entity.state.key === 'NEW' ? 'nx3_message_invite_carrier_success' : 'nx3_message_reinvite_carrier_success'
                      );
                      this.nx3.toast.success(
                        message,
                        this.nx3.i18n.translate.instant(
                          inviteButtonName
                        )
                      );
                    },
                    (error: any) => {
                      this.nx3.spinner.hide();
                      const command = new AlertDialogCommand(
                        'nx3_label_error',
                        error.response.data.messageKey,
                        'ban',
                        'nx3-color-danger nx3-text-extra-large',
                        null,
                        null
                      );
                      this.nx3.client.events.fire(command);
                    }
                  );
              }
            )
          );
        })
          .withExists(
            new BehaviorSubject(
              event.component.entity.state.key !== 'SUCCESSFULLY_INVITED' &&
              this.nx3.client.auth.hasAuthorities(['CARRIER_INVITATION_UPDATE'])
            )
          )
          .withOverflow(false)
          .withCssClass(this.nx3.config.getButtonClass('success'))
          .withTooltip(event.component.entity.state.key === 'NEW' ? 'nx3_message_invite_carrier_confirm' : 'nx3_message_reinvite_carrier_confirm'),
        DetailConfig.BUTTON_GROUP_TOP
      )
    );
  }

}

