import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localePl from '@angular/common/locales/pl';
import { Component, OnInit } from '@angular/core';
import {
  DashboardLayoutService,
  EntityDataQualityComponent,
  ExtensionService,
  FieldWidgetFactory,
  Nx3Api,
  ResourceExtension,
} from '@nx3/nx3-core-ui';
import { CustomDocumentUploadUserTaskExtension } from 'projects/shared/src/lib/resource-extension/custom-document-upload-user-task-extension';
import { CustomRegionExtension } from 'projects/shared/src/lib/resource-extension/custom-region-extension';
import { EnvironmentalManagementUserTaskExtension } from 'projects/shared/src/lib/resource-extension/environmental-management-user-task-extension';
import { IbanMaintainedUserTaskExtension } from 'projects/shared/src/lib/resource-extension/iban-maintained-user-task-extension';
import { MinimumWageUserTaskExtension } from 'projects/shared/src/lib/resource-extension/minimum-wage-user-task-extension';
import { RegionRelationExtension } from 'projects/shared/src/lib/resource-extension/region-relation-extension';
import { RequirementProfileUserTask } from 'projects/shared/src/lib/resource-extension/requirement-profile-user-task-extension';
import { VehicleExtension } from 'projects/shared/src/lib/resource-extension/vehicle-extension';
import { AppExtension } from './app-extension';
import { CustomAuditLogApprovalUserTaskExtension } from './resource-extensions/auditlogapprovalusertask-extension';
import { BackofficeDocumentExtension } from './resource-extensions/backoffice-document-extension';
import { CarrierExtension } from './resource-extensions/carrier-extension';
import { CarrierCandidateExtension } from './resource-extensions/carriercandidate-extension';
import { CarrierCandidatesTransfBatchExtension } from './resource-extensions/carriercandidatestransfbatch-extension';
import { CarrierInvitationExtension } from './resource-extensions/carrierinvitation-extension';
import { ChoosePartnernumberUserTaskExtension } from './resource-extensions/choosepartnernumberusertask-extension';
import { CustomDocumentsshareExtension } from './resource-extensions/custom-documentsshare-extension';
import { CustomDocumentTemplateExtension } from './resource-extensions/custom-documenttemplate-extension';
import { CustomEmployeeExtension } from './resource-extensions/custom-employee-extension';
import { CustomImageExtension } from './resource-extensions/custom-image-extension';
import { CustomMediafileExtension } from './resource-extensions/custom-mediafile-extension';
import { CustomQualificationExtension } from './resource-extensions/custom-qualification-extension';
import { CustomQuestionnaireExtension } from './resource-extensions/custom-questionnaire-extension';
import { CustomVerificationDefinitionExtension } from './resource-extensions/custom-verification-definition-extension';
import { CustomDocumentVerificationDecisionUserTaskExtension } from './resource-extensions/customdocumentverificationdecisionusertask-extension';
import { DefaultExtension } from './resource-extensions/default-extension';
import { DepartmentExtension } from './resource-extensions/department-extension';
import { DocumentDecisionUserTaskExtension } from './resource-extensions/documentdecisionusertask-extension';
import { SanctionListCheckUserTaskExtension } from './resource-extensions/sanctionlistcheckusertask-extension';
import { TenderBidExtension } from './resource-extensions/tender-bid-extension';
import { TenderExtension } from './resource-extensions/tender-extension';
import { UserTaskExtension } from './resource-extensions/usertask-extension';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(private nx3: Nx3Api) {}

  /**
   * The applications first initialization callback
   */
  ngOnInit(): void {
    registerLocaleData(localeDe, 'de');
    registerLocaleData(localePl, 'en');

    const extensionService = this.nx3.injector.get(ExtensionService);

    // register an application extension that extends behaviour of the global application layout and global components
    extensionService.registerApplicationExtension(
      new AppExtension(
        this.nx3,
        this.nx3.injector.get(FieldWidgetFactory),
        this.nx3.injector.get(DashboardLayoutService)
      )
    );

    // launch the resource extension system
    extensionService.lazyConfigureResourceExtensions(
      // register new extensions or override the default extensions with custom ones
      [
        BackofficeDocumentExtension,
        CarrierCandidateExtension,
        CarrierCandidatesTransfBatchExtension,
        CarrierExtension,
        CarrierInvitationExtension,
        ChoosePartnernumberUserTaskExtension,
        CustomAuditLogApprovalUserTaskExtension,
        CustomDocumentUploadUserTaskExtension,
        CustomDocumentsshareExtension,
        CustomDocumentTemplateExtension,
        CustomEmployeeExtension,
        CustomImageExtension,
        CustomMediafileExtension,
        CustomQualificationExtension,
        CustomQuestionnaireExtension,
        CustomRegionExtension,
        CustomVerificationDefinitionExtension,
        DepartmentExtension,
        CustomDocumentVerificationDecisionUserTaskExtension,
        DocumentDecisionUserTaskExtension,
        EnvironmentalManagementUserTaskExtension,
        IbanMaintainedUserTaskExtension,
        MinimumWageUserTaskExtension,
        TenderBidExtension,
        TenderExtension,
        RegionRelationExtension,
        RequirementProfileUserTask,
        SanctionListCheckUserTaskExtension,
        UserTaskExtension,
        VehicleExtension,
      ],
      // default extension will be used for all resources that are not extended
      DefaultExtension,
      // post process all created extensions to apply global defaults
      (extension: ResourceExtension) => {
        extension.list.enableListSettings = false;
        extension.list.enableGuidedTour = false;
        extension.list.enableNotifications = false;
        extension.detail.enableNotifications = false;
        extension.detail.disabledComponents.push(EntityDataQualityComponent);
      }
    );

    // install navigation endpoints handling hooks for open-id server integration
    this.nx3.navigation.authEndpoints();

    // configure content security policy
    this.nx3.win.configureContentSecurityPolicy();
  }
}
