import { Component, OnInit } from '@angular/core';
import { Nx3BeanModel, Nx3Entity, ResourceConfig, ResourceModel } from '@nx3/nx3-client';
import { AbstractFieldWidgetComponent, ExtensionService, Nx3Api } from '@nx3/nx3-core-ui';

@Component({
  selector: 'app-carrier-reference-gerneral-information',
  templateUrl: './carrier-reference-gerneral-information.component.html',
  styleUrls: ['./carrier-reference-gerneral-information.component.scss']
})
export class CarrierReferenceGerneralInformationComponent extends AbstractFieldWidgetComponent implements OnInit {

  metadata;

  resourceModel;

  resourceConfig;

  entity;

  constructor(public nx3: Nx3Api, protected extensions: ExtensionService) {
    super(nx3);
  }

  ngOnInit(): void {
    this.nx3.client.resource
      .resourceModelByClassName(
        this.nx3.config.registrationServer,
        "de.seriea.nx3.transa.businesspartner.carrier.Carrier"
      )
      .subscribe((carrierResourceModel: ResourceModel) => {
        this.resourceModel = carrierResourceModel;
        this.nx3.client.entity
          .resourceConfigByName(
            this.nx3.config.registrationServer,
            carrierResourceModel.name
          )
          .subscribe((carrierResourceConfig: ResourceConfig) => {
            this.resourceConfig = carrierResourceConfig;
            this.nx3.client.metadata
                  .get(this.nx3.config.registrationServer, carrierResourceModel)
                  .subscribe((metadata: Nx3BeanModel) => {
                    this.metadata = metadata;
                    this.nx3.client.entity.findOne(carrierResourceConfig, this.getValue()).subscribe((carrier: Nx3Entity) => {
                      this.entity = carrier;
                    });
          });
        });
      });
  }

}
