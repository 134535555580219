import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  Nx3BeanModel,
  Nx3SearchFilter,
  ResourceConfig,
  ResourceModel,
} from '@nx3/nx3-client';
import {
  AbstractEntityComponent,
  DeleteConfig,
  EntitySelectionCommand,
  ExtensionService,
  ListConfig,
  Nx3Api,
} from '@nx3/nx3-core-ui';
import { forkJoin } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'nx3-businesspartner-carrierrelation',
  templateUrl: './carriercandidate-transformation.component.html',
  styleUrls: ['./carriercandidate-transformation.component.scss'],
})
export class CarrierCandidateTransformationComponent
  extends AbstractEntityComponent
  implements OnInit, OnDestroy
{
  carrierCandidateResourceConfig: ResourceConfig;
  carrierCandidateBeanModel: Nx3BeanModel;
  carrierCandidateResourceModel: ResourceModel;
  carrierCandidateSpecificationMetadata: Nx3BeanModel;
  filter: Nx3SearchFilter;
  listConfig: ListConfig = new ListConfig();
  deleteConfig: DeleteConfig = new DeleteConfig();
  initialized = false;

  constructor(public nx3: Nx3Api, protected extensions: ExtensionService) {
    super(nx3, extensions);
  }

  ngOnDestroy(): void {
    this.filter.specifications.showTransformed = false;
    this.filter.contextStore = undefined;
  }

  configureList() {
    this.listConfig.setFixedView(false);
    this.listConfig.setStickyLayout(false);
    this.listConfig.enableFilterModeToggle = false;
    this.listConfig.enableListSettings = false;
    this.listConfig.table.toolSelection = false;
    this.listConfig.openInModal = true;
    this.listConfig.header.visible = false;
    this.listConfig.header.sticky = false;
  }

  ngOnInit(): void {
    this.configureList();
    const observables = [];

    observables.push(
      this.nx3.client.resource.resourceModelByName(
        this.resourceConfig.server,
        'carriercandidate'
      )
    );

    forkJoin(observables)
      .pipe(take(1))
      .subscribe((data) => {
        this.carrierCandidateResourceModel = data[0] as any;
        this.carrierCandidateResourceConfig = ResourceConfig.forResourceModel(
          this.resourceConfig.server,
          this.carrierCandidateResourceModel
        );

        const innerObservables = [];
        innerObservables.push(
          this.nx3.client.metadata.get(
            this.resourceConfig.server,
            this.carrierCandidateResourceModel
          )
        );
        innerObservables.push(
          this.nx3.client.entity.specificationMetadata(
            this.carrierCandidateResourceConfig
          )
        );
        innerObservables.push(
          this.nx3.client.entity.getFilter(
            this.carrierCandidateResourceConfig,
            true
          )
        );

        forkJoin(innerObservables)
          .pipe(take(1))
          .subscribe((innerData: any[]) => {
            this.carrierCandidateBeanModel = innerData[0] as any;
            this.carrierCandidateSpecificationMetadata = innerData[1] as any;
            this.filter = innerData[2] as any;
            this.filter.specifications.showTransformed = true;
            this.filter.contextStore =
              EntitySelectionCommand.createSelectionContext(
                this.metadata,
                undefined,
                this.entity.id,
                undefined,
                'transformationCandidates'
              );
            this.initialized = true;
          });
      });
  }
}
