import {
  AlertDialogCommand,
  ButtonModel,
  ButtonRegistration,
  CommonNames,
  ConfirmDialogCommand,
  ContentPanelConfig,
  DetailConfig,
  DetailEvent,
  EntityComponentModel,
  FormlayoutComponent,
  ListConfig,
  ListEvent,
  nestedConfig,
  Nx3Api,
  preset,
} from '@nx3/nx3-core-ui';
import { SpreadsheetImportConfig } from '@nx3/nx3-core-ui/lib/nx3-import/spreadsheet-import/spreadsheet-import-config';
import { BehaviorSubject, of } from 'rxjs';
import { take } from 'rxjs/operators';
import { CarrierCandidateTransformationViolationsComponent } from './../components/transformation/transformation.component';
import { DefaultExtension } from './default-extension';

export class CarrierCandidateExtension extends DefaultExtension {
  constructor(public nx3: Nx3Api) {
    super(nx3, 'carriercandidate');
  }

  configureDetail(config: DetailConfig): void {
    const presets = [];

    presets.push(
      preset(
        CarrierCandidateTransformationViolationsComponent,
        nestedConfig(false)
          .withContentPanel(
            new ContentPanelConfig('violations', 'nx3_label_transformation')
          )
          .withExists((model: EntityComponentModel) => {
            return of(
              model.entity.transformation &&
                model.entity.transformation.constraintViolations &&
                new Map(
                  Object.entries(
                    model.entity.transformation.constraintViolations
                  )
                ).size > 0
            );
          }),
        {}
      )
    );

    presets.push(
      preset(
        FormlayoutComponent,
        nestedConfig(false)
          .mapModel('entity', 'object')
          .withExists((model: EntityComponentModel) => {
            return of(true);
          })
      )
    );

    config.layout.rootLayout().row().componentPresets(presets);
  }

  onDetailInit(event: DetailEvent): void {
    super.onDetailInit(event);
    this.detailInstance.registerButton(
      this.createTransformCandidateButton(
        event,
        new BehaviorSubject(event.component.entity.carrier)
      )
    );
  }

  onListInit(event: ListEvent) {
    super.onListInit(event);
    this.listInstance.registerButton(
      this.createTransformCandidateButton(
        event,
        event.component.buttonDisabledState
      )
    );
  }

  configureSpreadsheetImport(config: SpreadsheetImportConfig): void {
    config.showPreview = false; // otherwise need to long
    config.sampleFileUrl = '/assets/carrierimport.xlsx';
  }

  createTransformCandidateButton(
    event: ListEvent | DetailEvent,
    buttonDisabledState: BehaviorSubject<boolean>
  ): ButtonRegistration {
    return new ButtonRegistration(
      new ButtonModel(
        'nx3_button_transform_carriercandidate',
        undefined,
        () => {
          this.nx3.modal.confirm(
            new ConfirmDialogCommand(
              'nx3_button_transform_carriercandidate',
              'nx3_message_transform_carriercandidate_confirm',
              () => {
                this.nx3.spinner.show();

                let selectedIds: string[];
                if (event instanceof ListEvent) {
                  selectedIds = event.component.selection.map(({ id }) => id);
                } else if (event instanceof DetailEvent) {
                  selectedIds = [event.component.entity.id];
                }

                this.nx3.client.rest
                  .post(
                    event.component.resourceConfig.server,
                    '/api/carriercandidate/transform',
                    selectedIds
                  )
                  .pipe(take(1))
                  .subscribe(
                    (transfBatchId) => {
                      this.nx3.spinner.hide();

                      this.nx3.router.navigate([
                        '',
                        CommonNames.DETAIL,
                        event.component.resourceConfig.server,
                        'carriercandidatestransfbatch',
                        transfBatchId,
                        'carriercandidatestransfbatch',
                      ]);
                    },
                    (error: any) => {
                      this.nx3.spinner.hide();
                      const command = new AlertDialogCommand(
                        'nx3_label_error',
                        error.response.data.messageKey,
                        'ban',
                        'nx3-color-danger nx3-text-extra-large',
                        null,
                        null
                      );
                      this.nx3.client.events.fire(command);
                    }
                  );
              }
            )
          );
        }
      )
        .withDisabled(buttonDisabledState)
        .withOverflow(false)
        .withIcon('road')
        .withExists(
          new BehaviorSubject(
            !this.nx3.client.auth.hasSecurityGroup('DISPONENT_GROUP')
          )
        )
        .withCssClass(this.nx3.config.getButtonClass('primary'))
        .withHotkey(this.nx3.hotkeys.createTrigger('t'))
        .withTooltip('nx3_message_transform_carriercandidate_confirm'),
      ListConfig.BUTTON_GROUP_TOP
    );
  }

  configureList(config: ListConfig) {
    config.enableCreate = false;
    config.enableGuidedTour = false;
    config.enableExportJson = false;
  }
}
