import { Nx3BeanModel, Nx3DataType, Nx3Entity, Nx3FieldModel, Nx3FormRow, ResourceConfig } from '@nx3/nx3-client';
import {
  ButtonModel,
  ButtonRegistration,
  CommonNames,
  DetailComponent,
  DetailConfig,
  DetailEvent,
  EditorConfig,
  EditorEvent,
  FormlayoutComponent,
  ListComponent,
  ListEvent,
  Nx3Api,
  nestedConfig,
} from '@nx3/nx3-core-ui';
import { BehaviorSubject, concat, of, take } from 'rxjs';
import { AbstractPortalExtension } from '../../../../portal/src/app/resource-extensions/abstract-portal-extension';
import { MultipleRelationsComponent } from '../multiple-relations/multiple-relations.component';
import { NutsRegionsComponent } from '../nuts-regions/nuts-regions.component';
import { SelectRegionFieldComponent } from '../select-region-field/select-region-field.component';

export class RegionRelationExtension extends AbstractPortalExtension {
  constructor(public nx3: Nx3Api) {
    super('regionrelation', nx3);
  }

  configureDetail(config: DetailConfig): void {
    config.layout
      .rootLayout()
      .row()
      .componentPresets(this.detailLayout().topPresets)
      .up()
      .row()
      .nestedColumn(12)
      .component(FormlayoutComponent, nestedConfig(false).mapModel('entity', 'object'))
      .exitNesting()
      .up()
      .row()
      .nestedColumn(12)
      .componentPresets(this.detailLayout().bottomPresets)
      .exitNesting()
      .up();
  }

  onDetailInit(event: DetailEvent): void {
    this.createFromToMapField(event.component.metadata, true);
    this.createReversingButton(event.component, event.component.resourceConfig);
  }

  onListInit(event: ListEvent): void {
    this.createReversingButton(event.component, event.component.resourceConfig);
  }

  onEditorAfterView(event: EditorEvent): void {
    const fromRegion = event.component.form.get('fromRegion');
    fromRegion.valueChanges.subscribe((value) => {
      if (value) {
        this.nx3.client.events.fireEvent('relationChanged');
      }
    });
    const toRegion = event.component.form.get('toRegion');
    toRegion.valueChanges.subscribe((value) => {
      if (value) {
        this.nx3.client.events.fireEvent('relationChanged');
      }
    });
  }

  onEditorInit(event: EditorEvent): void {
    event.component.config = new EditorConfig();

    event.component.config.saveHandler = (entity) => {
      this.nx3.spinner.show();

      const fromRegions = [];
      const toRegions = [];
      const saveOperations = [];
      for (const property in this.editorInstance.entity) {
        if (property.startsWith('fromRegion')) {
          const num = Number.parseInt(property.split('fromRegion')[1]);
          if (this.editorInstance.entity[property] && !Number.isNaN(num)) {
            fromRegions.push({
              region: this.editorInstance.entity[property],
              note: this.editorInstance.entity['fromRegionNote' + num],
            });
            delete this.editorInstance.entity[property];
            delete this.editorInstance.entity['fromRegionNote' + num];
          }
        }
      }
      for (const property in this.editorInstance.entity) {
        if (property.startsWith('toRegion')) {
          const num = Number.parseInt(property.split('toRegion')[1]);
          if (this.editorInstance.entity[property] && !Number.isNaN(num)) {
            toRegions.push({
              region: this.editorInstance.entity[property],
              note: this.editorInstance.entity['toRegionNote' + num],
            });
            delete this.editorInstance.entity[property];
            delete this.editorInstance.entity['toRegionNote' + num];
          }
        }
      }
      if (fromRegions.length !== toRegions.length) {
        this.editorInstance.nx3.toast.error('nx3_error_relations_not_complete');
        return;
      }

      for (const [i] of fromRegions.entries()) {
        const copy = this.nx3.client.plainToClass(Nx3Entity, this.editorInstance.entity);
        copy.fromRegion = fromRegions[i].region;
        copy.fromRegionNote = fromRegions[i].note;
        copy.toRegion = toRegions[i].region;
        copy.toRegionNote = toRegions[i].note;
        delete copy.fromRegion.area;
        delete copy.toRegion.area;
        saveOperations.push(this.nx3.client.entity.save(this.editorInstance.resourceConfig, copy).pipe(take(1)));
      }

      if (this.editorInstance.entity.addTripBack) {
        for (const [i] of fromRegions.entries()) {
          const copy = this.nx3.client.plainToClass(Nx3Entity, this.editorInstance.entity);
          copy.fromRegion = toRegions[i].region;
          copy.fromRegionNote = toRegions[i].note;
          copy.toRegion = fromRegions[i].region;
          copy.toRegionNote = fromRegions[i].note;
          delete copy.fromRegion.area;
          delete copy.toRegion.area;
          saveOperations.push(this.nx3.client.entity.save(this.editorInstance.resourceConfig, copy).pipe(take(1)));
        }
        const copy = this.nx3.client.plainToClass(Nx3Entity, this.editorInstance.entity);
        copy.fromRegion = this.editorInstance.entity.toRegion;
        copy.fromRegionNote = this.editorInstance.entity.toRegionNote;
        copy.toRegion = this.editorInstance.entity.fromRegion;
        copy.toRegionNote = this.editorInstance.entity.fromRegionNote;
        delete copy.fromRegion.area;
        delete copy.toRegion.area;
        saveOperations.push(this.nx3.client.entity.save(this.editorInstance.resourceConfig, copy).pipe(take(1)));
      }

      delete this.editorInstance.entity.fromRegion.area;
      delete this.editorInstance.entity.toRegion.area;

      saveOperations.push(
        this.nx3.client.entity.save(this.editorInstance.resourceConfig, this.editorInstance.entity).pipe(take(1))
      );

      concat(...saveOperations).subscribe({
        complete: () => {
          this.nx3.spinner.hide();
          if (this.nx3.getCurrentRouteComponent().resourceName === 'regionrelation') {
            this.editorInstance.saveSuccess();
            this.nx3.router.navigate([
              CommonNames.LIST,
              this.editorInstance.resourceConfig.server,
              this.editorInstance.resourceConfig.resourceName,
            ]);
          }
        },
      });

      return of(null);
    };

    event.component.metadata.getFieldByName('fromRegion').componentType = SelectRegionFieldComponent;
    event.component.metadata.getFieldByName('toRegion').componentType = SelectRegionFieldComponent;
    if (!event.component.metadata.getFieldByName('multipleRelations')) {
      const multipleRelations = new Nx3FieldModel();
      multipleRelations.name = 'multipleRelations';
      multipleRelations.type = Nx3DataType.TEXT;
      multipleRelations.required = false;
      multipleRelations.labelKey = 'multipleRelations';
      multipleRelations.labelHidden = true;
      multipleRelations.componentType = MultipleRelationsComponent;
      multipleRelations.readonly = false;
      multipleRelations.transientProperty = true;
      multipleRelations.span = 4;
      const multipleRelationsRow = new Nx3FormRow();
      multipleRelationsRow.fields = ['multipleRelations'];
      event.component.metadata.addField(multipleRelations);
      event.component.metadata.getFormSectionByLabel('section_main').rows.push(multipleRelationsRow);
    }
    this.createFromToMapField(event.component.metadata, false);
  }

  createReversingButton(component: DetailComponent | ListComponent, resourceConfig: ResourceConfig) {
    component.registerButton(
      new ButtonRegistration(
        new ButtonModel('nx3_button_create_reversing_relation', 'create_reversing_relation', (event: any) => {
          let entity: Nx3Entity;

          if (component instanceof ListComponent) {
            component = component as ListComponent;
            entity = component.selection[0];
          } else {
            component = component as DetailComponent;
            entity = component.entity;
          }

          this.nx3.flash.set(CommonNames.CREATE_PARAMS, {
            carrier: entity.carrier,
            fromRegionId: entity.toRegion.id,
            toRegionId: entity.fromRegion.id,
            vehicleType: entity.vehicleType,
          });

          const commands = ['', CommonNames.EDITOR, resourceConfig.server, resourceConfig.resourceName, '-1'];
          this.nx3.navigation.navigate(commands);
        })
          .withOverflow(false)
          .withIcon('right-left')
          .withTooltip('nx3_button_create_reversing_relation')
          .withDisabled(
            component instanceof ListComponent ? (component as ListComponent).buttonDisabledState : new BehaviorSubject(false)
          )
          .withExists(
            new BehaviorSubject(this.nx3.client.auth.hasResourceAuthority(component.resourceModel.authority, 'UPDATE'))
          ),
        'top'
      )
    );
  }

  createFromToMapField(metadata: Nx3BeanModel, readonly: boolean) {
    if (!metadata.getFieldByName('fromToMap')) {
      const fromToMap = new Nx3FieldModel();
      fromToMap.name = 'fromToMap';
      fromToMap.type = Nx3DataType.TEXT;
      fromToMap.required = false;
      fromToMap.labelKey = 'fromToMap';
      fromToMap.labelHidden = true;
      fromToMap.componentType = NutsRegionsComponent;
      fromToMap.readonly = readonly;
      fromToMap.transientProperty = true;
      fromToMap.span = 4;
      const fromToMapRow = new Nx3FormRow();
      fromToMapRow.fields = ['fromToMap'];
      metadata.addField(fromToMap);
      metadata.getFormSectionByLabel('section_main').rows.push(fromToMapRow);
    }
  }
}
