<div *ngIf="violations && violations.size > 0" class="table-responsive">
  <table class="table table-hover mb-0">
    <thead>
      <tr>
        <th>
          {{ 'nx3_label_fieldname' | translate }}
        </th>
        <th>
          {{ 'nx3_label_constraintviolation' | translate }}
        </th>
      </tr>
    </thead>
    <tbody class="nx3-color-danger">
      <tr *ngFor="let violationKey of violationKeys">
        <td>
          {{ getViolationMessageKey(violationKey) | translate  }}
        </td>
        <td>
          {{ violations.get(violationKey) | translate }}
        </td>
      </tr>
    </tbody>
  </table>
</div>
