import { Component, OnInit } from '@angular/core';
import {
  AbstractEntityComponent,
  ExtensionService,
  Nx3Api,
} from '@nx3/nx3-core-ui';

@Component({
  selector: 'nx3-carriercandidate-trnsformation',
  templateUrl: './transformation.component.html',
  styleUrls: ['./transformation.component.scss'],
})
export class CarrierCandidateTransformationViolationsComponent
  extends AbstractEntityComponent
  implements OnInit
{
  constructor(public nx3: Nx3Api, protected extensions: ExtensionService) {
    super(nx3, extensions);
  }

  violations: Map<string, string>;
  violationKeys: string[];

  ngOnInit(): void {
    if (
      this.entity.transformation &&
      this.entity.transformation.constraintViolations
    ) {
      const violations = new Map<string, string>(
        Object.entries(this.entity.transformation.constraintViolations)
      );
      this.violations = violations;
      const violationKeys: string[] = [];
      for (const key of this.violations.keys()) {
        violationKeys.push(key);
      }
      this.violationKeys = violationKeys;
    }
  }

  getViolationMessageKey(violationKey: string): string {
    if (!violationKey) {
      return undefined;
    }
    let key = violationKey;
    if (violationKey.includes('.')) {
      key = violationKey.split('.').pop();
    }
    return 'property_' + key + '_label';
  }
}
