<h4>{{ 'transa_label_trias_duplicatecheck' | translate }}</h4>
<p>
  <nx3-icon
    *ngIf="duplicateIntegrationFailed"
    icon="circle-exclamation"
    styles="text-danger me-2"
  ></nx3-icon>
  <nx3-icon
    *ngIf="
      currentDuplicateState &&
      currentDuplicateState.handlerType !== 'deliveredChannelDup'
    "
    icon="circle-check"
    styles="text-warning me-2"
  ></nx3-icon>
  <nx3-icon
    *ngIf="
      currentDuplicateState &&
      currentDuplicateState.handlerType === 'responseObjectChannelDup'
    "
    icon="circle-check"
    styles="text-success me-2"
  ></nx3-icon>

  <ng-container *ngIf="duplicateIntegrationFailed">{{
    'transa_label_trias_duplicatecheck_error' | translate
  }}</ng-container>
  <ng-container *ngIf="currentDuplicateState">
    {{ 'transa_label_trias_current_state' | translate }}:
    {{ 'transa_label_' + currentDuplicateState.handlerType | translate }} @
    {{ currentDuplicateState.modifiedAt | date: 'dd.MM.YYYY HH:mm:ss' }}
  </ng-container>
  <ng-container *ngIf="!duplicateIntegrationFailed && !currentDuplicateState">{{
    'transa_label_trias_duplicatecheck_not_started' | translate
  }}</ng-container>
</p>
<h4>{{ 'transa_label_trias_exchange' | translate }}</h4>
<p>
  <nx3-icon
    *ngIf="exchangeIntegrationFailed"
    icon="circle-exclamation"
    styles="text-danger me-2"
  ></nx3-icon>
  <nx3-icon
    *ngIf="
      currentExchangeState &&
      currentExchangeState.handlerType !== 'deliveredChannelEx'
    "
    icon="circle-check"
    styles="text-warning me-2"
  ></nx3-icon>
  <nx3-icon
    *ngIf="
      currentExchangeState &&
      currentExchangeState.handlerType === 'successChannelEx'
    "
    icon="circle-check"
    styles="text-success me-2"
  ></nx3-icon>

  <ng-container *ngIf="exchangeIntegrationFailed">{{
    'transa_label_trias_exchange_error' | translate
  }}</ng-container>
  <ng-container *ngIf="currentExchangeState">
    {{ 'transa_label_trias_current_state' | translate }}:
    {{ 'transa_label_' + currentExchangeState.handlerType | translate }} @
    {{ currentExchangeState.modifiedAt | date: 'dd.MM.YYYY HH:mm:ss' }}
  </ng-container>
  <ng-container *ngIf="!exchangeIntegrationFailed && !currentExchangeState">{{
    'transa_label_trias_exchange_not_started' | translate
  }}</ng-container>
</p>
<h4>{{ 'transa_label_trias_partnernumber' | translate }}</h4>
<p>
  <nx3-icon
    *ngIf="partnernumberIntegrationFailed"
    icon="circle-exclamation"
    styles="text-danger me-2"
  ></nx3-icon>
  <nx3-icon
    *ngIf="
      currentPartnernumberState &&
      currentPartnernumberState.handlerType !== 'deliveredChannelNo'
    "
    icon="circle-check"
    styles="text-warning me-2"
  ></nx3-icon>
  <nx3-icon
    *ngIf="
      currentPartnernumberState &&
      currentPartnernumberState.handlerType === 'updatePartnernumberChannelNo'
    "
    icon="circle-check"
    styles="text-success me-2"
  ></nx3-icon>

  <ng-container *ngIf="partnernumberIntegrationFailed">{{
    'transa_label_trias_partnernumber_error' | translate
  }}</ng-container>
  <ng-container *ngIf="currentPartnernumberState">
    {{ 'transa_label_trias_current_state' | translate }}:
    {{ 'transa_label_' + currentPartnernumberState.handlerType | translate }} @
    {{ currentPartnernumberState.modifiedAt | date: 'dd.MM.YYYY HH:mm:ss' }}
  </ng-container>
  <ng-container
    *ngIf="!partnernumberIntegrationFailed && !currentPartnernumberState"
    >{{
      'transa_label_trias_partnernumber_not_started' | translate
    }}</ng-container
  >
</p>
