import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Nx3FieldModel } from '@nx3/nx3-client';
import { AbstractFieldWidgetComponent, Nx3Api } from '@nx3/nx3-core-ui';

@Component({
  selector: 'lib-multiple-relations',
  templateUrl: './multiple-relations.component.html',
  styleUrls: ['./multiple-relations.component.scss'],
})
export class MultipleRelationsComponent
  extends AbstractFieldWidgetComponent
  implements OnInit
{
  fields: Nx3FieldModel[] = [];

  rows = 0;

  constructor(public nx3: Nx3Api) {
    super(nx3);
  }

  addRelation() {
    this.rows++;
    this.addRegionFormField('fromRegion' + this.rows, 'fromRegion');
    this.addFormField('fromRegionNote' + this.rows, 'fromRegionNote');
    this.addRegionFormField('toRegion' + this.rows, 'toRegion');
    this.addFormField('toRegionNote' + this.rows, 'toRegionNote');
  }

  addRegionFormField(name: string, copyFromName: string) {
    this.addFormField(name, copyFromName);

    this.control.valueChanges.subscribe((value) => {
      if(value) {
        this.nx3.client.rest
          .get('rs1', '/api/region/' + value.id)
          .subscribe((region) => {
            value.area = region.area;
            this.nx3.client.events.fireEvent('relationChanged');
          });
      }
    });
  }

  addFormField(name: string, copyFromName: string) {
    this.control = new UntypedFormControl(
      undefined,
      this.formConfig.formControlOptions,
      this.formConfig.asyncValidator
    );
    this.form.addControl(name, this.control);
    const field = AbstractFieldWidgetComponent.cloneField(
      this.nx3,
      this.metadata.getFieldByName(copyFromName),
      name
    );
    this.fields.push(field);
    this.object[name] = undefined;
  }
}
