import {
  ContentPanelConfig,
  DetailConfig,
  EntityComponentModel,
  newConfig,
  Nx3Api,
} from '@nx3/nx3-core-ui';
import { of } from 'rxjs';
import { BusinesspartnerCarrierRelationComponent } from '../components/businesspartner-carrierrelation/businesspartner-carrierrelation.component';
import { DefaultExtension } from './default-extension';

export class DepartmentExtension extends DefaultExtension {
  hideReferenceListNames = ['nx3usertask'];

  constructor(public nx3: Nx3Api) {
    super(nx3, 'department');
  }

  configureDetail(config: DetailConfig): void {
    super.configureDetail(config);

    this.detailLayout().createDefaultLayout(
      this.nx3,
      config,
      config.layout.rootLayout()
    );

    config.layout
      .rootLayout()
      .rows[1].columns[1].asLayout()
      .component(
        BusinesspartnerCarrierRelationComponent,
        newConfig(12, true)
          .withContentPanel(
            new ContentPanelConfig('users', 'carrier_list', 'p-2').setValue(
              'collapsible',
              false
            )
          )
          .withExists((model: EntityComponentModel) => {
            return of(model.entity.actor?.hasOwnProperty('legalForm'));
          })
      )
      .position(BusinesspartnerCarrierRelationComponent, 0);
  }
}
