import { Component } from '@angular/core';
import { Nx3Entity, ResourceConfig, ResourceModel } from '@nx3/nx3-client';
import { AbstractFieldWidgetComponent, EntitySelectionCommand, Nx3Api, SelectionType } from '@nx3/nx3-core-ui';
import { take } from 'rxjs';


@Component({
  selector: 'lib-select-region-field',
  templateUrl: './select-region-field.component.html',
  styleUrls: ['./select-region-field.component.scss'],
})
export class SelectRegionFieldComponent extends AbstractFieldWidgetComponent {
  options: any[];
  value = {id: null, key: null, description: null};
  resourceConfig: ResourceConfig;
  resourceModel: ResourceModel;

  constructor(public nx3: Nx3Api) {
    super(nx3);
  }

  init() {
    super.init();

    if(this.field.getValue(this.object)) {
      const region = this.field.getValue(this.object);
      this.value.id = region.id;
      this.value.key = region.key;
      this.value.description = region.key + ' | ' + region.localizedName.text;
    }

    this.nx3.spinner.show(
      this.nx3.i18n.translate.instant('nx3_label_load_regions') + ' ...'
    );
    this.loadOptions();

    const server = this.nx3.config.registrationServer;
    this.nx3.client.resource
      .resourceModelByName(server, 'nx3region')
      .pipe(take(1))
      .subscribe(
        (resourceModel: ResourceModel) => {
          this.resourceModel = resourceModel;
          this.resourceConfig = ResourceConfig.forResourceModel(server, resourceModel);
        },
        (error: any) => {
          this.nx3.errorhandler.alert(error);
        }
      );
  }

  loadOptions() {
    setTimeout(() => {
      const storageKey = this.nx3.client.storage.getClientBoundKey('regions_name_list');

      if(this.nx3.client.storage.session.exists(storageKey)) {
        this.options = JSON.parse(
          this.nx3.client.storage.session.get(storageKey)
        );
        this.nx3.spinner.hide();
      } else {
        this.loadOptions();
      }
    });
  }

  onSelect(event: any): void {
    if(event.item?.id) {
      this.value.id = event.item.id;
      this.value.key = event.item.key;
      this.value.description = event.item.description;
      this.nx3.client.rest
        .get('rs1', this.resourceModel.url + '/' + event.item.id)
        .subscribe((region) => {
          this.control.setValue(region);
          this.field.setValue(this.object, region);
        });
    }
  }

  onBlur(): void {
    const foundRegion = this.options.find((option) => option.key === this.value.key);
    if(foundRegion) {
      this.value.id = foundRegion.id;
      this.value.key = foundRegion.key;
      this.value.description = foundRegion.description;
      this.nx3.client.rest
        .get('rs1', this.resourceModel.url + '/' + foundRegion.id)
        .subscribe((region) => {
          this.control.setValue(region);
          this.field.setValue(this.object, region);
        });
    } else {
      this.control.setValue(null);
      this.field.setValue(this.object, null);
      this.value.id = null;
      this.value.key = null;
      this.value.description = null;
    }
  }

  selectRegion() {
    this.nx3.modal.selectEntity(
      new EntitySelectionCommand(
        this.resourceConfig,
        this.resourceModel,
        SelectionType.SINGLE,
        (selection: Nx3Entity[]) => {
          this.value.id = selection[0].id;
          this.value.key = selection[0].key;
          this.value.description = selection[0].key + ' | ' + selection[0].localizedName.text;
          this.field.setValue(this.object, selection[0]);
          this.control.setValue(selection[0]);
        }
      )
    );
  }

}
