import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Nx3CmsModule, Nx3ComponentsModule } from '@nx3/nx3-core-ui';
import { SharedModule } from 'projects/shared/src/lib/shared.module';
import { BusinesspartnerCarrierRelationComponent } from './businesspartner-carrierrelation/businesspartner-carrierrelation.component';
import { CarrierReferenceGerneralInformationComponent } from './carrier-reference-gerneral-information/carrier-reference-gerneral-information.component';
import { CarrierCandidateTransformationComponent } from './carriercandidate-transformation/carriercandidate-transformation.component';
import { ChoosePartnernumberComponent } from './choose-partnernumber/choose-partnernumber.component';
import { CustomUserdetailsFieldComponent } from './custom-userdetails-field/custom-userdetails-field.component';
import { DocumentsShareChartsComponent } from './documents-share-charts/documents-share-charts.component';
import { FinishTenderModalComponent } from './finish-tender-modal/finish-tender-modal.component';
import { MessageQueueInformationsComponent } from './message-queue-informations/message-queue-informations.component';
import { PriceDifferenceFieldComponent } from './price-difference-field/price-difference-field.component';
import { SanctionListCheckInformationComponent } from './sanction-list-check-information/sanction-list-check-information.component';
import { CarrierCandidateTransformationViolationsComponent } from './transformation/transformation.component';
import { FleetSpecificationComponent } from './fleet-specification/fleet-specification.component';

/**
 * Custom components specialized for this application
 */
@NgModule({
  declarations: [
    BusinesspartnerCarrierRelationComponent,
    CarrierCandidateTransformationComponent,
    CarrierCandidateTransformationViolationsComponent,
    CarrierReferenceGerneralInformationComponent,
    ChoosePartnernumberComponent,
    CustomUserdetailsFieldComponent,
    DocumentsShareChartsComponent,
    MessageQueueInformationsComponent,
    PriceDifferenceFieldComponent,
    SanctionListCheckInformationComponent,
    FinishTenderModalComponent,
    FleetSpecificationComponent
  ],
  exports: [],
  imports: [CommonModule, Nx3ComponentsModule, Nx3CmsModule, SharedModule],
})
export class ComponentsModule {}
