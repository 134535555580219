import {
  ButtonModel,
  DetailComponent,
  DetailConfig,
  EditorComponent,
  EditorEvent,
  ListComponent,
  ModalComponent,
  Nx3Api,
  Nx3UserTaskExtension,
  newConfig,
} from '@nx3/nx3-core-ui';
import { CarrierReferenceGerneralInformationComponent } from '../components/carrier-reference-gerneral-information/carrier-reference-gerneral-information.component';
import { ChoosePartnernumberComponent } from '../components/choose-partnernumber/choose-partnernumber.component';

export class ChoosePartnernumberUserTaskExtension extends Nx3UserTaskExtension {
  modalInstance: ModalComponent;
  completeButton: ButtonModel;

  constructor(public nx3: Nx3Api, resourceName = 'choosepartnernumberusertask') {
    super(nx3, resourceName);
  }

  configureModal(list: ListComponent, detail: DetailComponent, modal: ModalComponent) {
    this.completeButton = this.createCompleteButton(list, detail, modal);
    modal.buttons.push(this.completeButton);
  }

  configureDetail(config: DetailConfig) {
    config.enablePlaces = false;
    config.windowTitle = false;
    config.header.visible = false;
    config.layout.rootLayout().withUseContainer(false).withPadding(2, 0).row().component(EditorComponent, newConfig(12)).up();
  }

  onEditorInit(event: EditorEvent): void {
    event.component.metadata.getFieldByName('carrierId').componentType = CarrierReferenceGerneralInformationComponent;
    event.component.metadata.getFieldByName('carrierId').labelKey = 'carrier_label';
    event.component.metadata.getFieldByName('duplicateResultList').componentType = ChoosePartnernumberComponent;

    this.nx3.client.events.on(
      'CHOOSE_PARTNERNUMBER',
      (completeEvent) => {
        event.component.entity.partnernumber = completeEvent.data.partnernumber;
        this.completeButton.click(null);
      },
      this
    );
  }

  onEditorDestroy(event: EditorEvent) {
    this.nx3.client.events.unregister('CHOOSE_PARTNERNUMBER', this);
  }
}
