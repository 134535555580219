import {
  AbstractNx3ClientConfig,
  Nx3Client,
  SyncStorageType,
} from '@nx3/nx3-client';

export class Nx3ClientConfig extends AbstractNx3ClientConfig {
  logging = false;
  name = 'transa_backoffice';
  clientId = 'backoffice';
  loglevel = 'trace';
  authTokenUrl = '___nx3_substitute_token_url___';
  authUrl = '___nx3_substitute_auth_url___';
  redirectUrl = '___nx3_substitute_redirect_backoffice_url___';
  httpTimeout = 1200000;
  servers = [{ name: 'rs1', url: '___nx3_substitute_rs1_backoffice_url___' }];
  userdataStorage = SyncStorageType.MEMORY;
  refreshTokenTimer = true;
  geoServiceKey = '___nx3_substitute_geo_service_key___';
}

export const environment = {
  production: false,
  nx3Client: new Nx3Client(new Nx3ClientConfig()),
};
