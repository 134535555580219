import { Component } from '@angular/core';
import { UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { Nx3BeanModel, Nx3SearchComposition, ResourceConfig, ResourceModel, Nx3SearchOperator } from '@nx3/nx3-client';
import { AbstractSpecificationFieldWidgetComponent, Nx3Api } from '@nx3/nx3-core-ui';
import { take } from 'rxjs/operators';

/**
 * Creates fleet filter specification
 *
 * @author martin.bueltel(at)serie-a.de
 */
@Component({
  selector: 'app-fleet-specification',
  templateUrl: './fleet-specification.component.html',
  styleUrls: ['./fleet-specification.component.scss'],
})
export class FleetSpecificationComponent extends AbstractSpecificationFieldWidgetComponent {

  section: any[] = [];
  fleet: any;
  activeCat;
  isVehicleAmountsSet = false;
  isTrailerTypesSet = false;
  isTotalFleetSet = false;
  isEquipmentTypesSet = false;
  operators = [
    Nx3SearchOperator.EQUAL,
    Nx3SearchOperator.GREATER_THAN,
    Nx3SearchOperator.GREATER_THAN_OR_EQUAL,
    Nx3SearchOperator.LESS_THAN,
    Nx3SearchOperator.LESS_THAN_OR_EQUAL
  ];

  constructor(public nx3: Nx3Api) {
    super(nx3);
  }

  init(): void {
    super.init();

    this.nx3.client.resource
    .resourceModelByClassName(this.nx3.config.registrationServer, "de.seriea.nx3.transa.businesspartner.fleet.Fleet")
    .pipe(take(1))
    .subscribe((resourceModel: ResourceModel) => {
      this.nx3.client.entity
      .resourceConfigByName(this.nx3.config.registrationServer, resourceModel.name)
      .pipe(take(1))
      .subscribe((resourceConfig: ResourceConfig) => {
        this.resourceConfig = resourceConfig;

        this.nx3.client.metadata
        .get(this.nx3.config.registrationServer, resourceModel)
        .pipe(take(1))
        .subscribe((metadata: Nx3BeanModel) => {
          this.metadata = metadata;

          this.nx3.client.rest.get(resourceConfig.server, resourceModel.url + '/filterproperties')
          .pipe(take(1))
          .subscribe((filterProperties) => {
            if(this.parameters.fleet) {
              this.fleet = this.parameters.fleet;
            } else {
              this.fleet = filterProperties;
            }

            if(!this.parameters.compositions) {
              this.parameters.compositions = {};
            }

            for (const section of Object.keys(filterProperties)) {
              if(!this.parameters.compositions[section]) {
                this.parameters.compositions[section] = Nx3SearchComposition.AND;
              }
              const sectionsFields = {name: section, fields: []};
              const field = metadata.getFieldByName(section);
              if(field) {
                if(field.name === 'vehicleAmounts') {
                  for (const col of field.listModel.columns) {
                    col.required = false;
                  }

                  if(this.form.get(section)) {
                    this.control = this.form.get(section);
                    (this.control as UntypedFormArray).controls = [];
                  } else {
                    this.control = new UntypedFormArray([]);
                    this.form.addControl(section, this.control);
                  }

                  if(this.fleet[section] && this.fleet[section].length > 0) {
                    for (const vehicle of this.fleet[section]) {
                      const formGroup = this.nx3.forms.createEmbeddedFormGroup(
                        field,
                        metadata,
                        vehicle,
                        this.formConfig,
                        resourceConfig
                      );
                      formGroup.patchValue(vehicle);
                      (this.control as UntypedFormArray).push(formGroup);
                    }
                  }

                  this.control.valueChanges.subscribe((value) => {
                    for(const v of this.fleet?.vehicleAmounts) {
                      if(!v.operator) {
                        v.operator = this.operators[0];
                      }
                    }
                  });
                } else {
                  if(this.form.get(section)) {
                    this.control = this.form.get(section) as UntypedFormControl;
                  } else {
                    this.control = new UntypedFormControl(
                      undefined,
                      this.formConfig.formControlOptions,
                      this.formConfig.asyncValidator
                    );
                    this.form.addControl(section, this.control);
                  }

                  if(this.fleet[section]) {
                    this.control.setValue(this.fleet[section]);
                  } else {
                    this.control.setValue(null);
                  }

                  if(!this.fleet.totalFleetOperator) {
                    this.fleet.totalFleetOperator = this.operators[0];
                  }
                }
                field.labelHidden = true;
                field.required = false;
                sectionsFields.fields.push(field);
              } else {

                for (const property of Object.keys(filterProperties[section])) {
                  if(this.form.get(property)) {
                    this.control = this.form.get(property) as UntypedFormControl;
                  } else {
                    this.control = new UntypedFormControl(
                      undefined,
                      this.formConfig.formControlOptions,
                      this.formConfig.asyncValidator
                    );
                    this.form.addControl(property, this.control);
                  }

                  if(this.fleet[section][property]) {
                    this.control.setValue(this.fleet[section][property]);
                  } else {
                    this.control.setValue(false);
                  }

                  sectionsFields.fields.push(metadata.getFieldByName(section + '.' + property));
                }
              }
              this.section.push(sectionsFields);
            }

            this.activeCat = this.section[0].name;
          });
        });
      });
    });
  }

  isSet(): boolean {
    this.isVehicleAmountsSet = this.fleet?.vehicleAmounts?.some(vehicleAmount => Object.values(vehicleAmount).some(value => value !== null && value !== undefined && value !== ''));
    this.isTrailerTypesSet = this.fleet?.trailerTypes && Object.values(this.fleet.trailerTypes).includes(true);
    this.isTotalFleetSet = this.fleet?.totalFleet > 0;
    this.isEquipmentTypesSet = this.fleet?.equipmentTypes && Object.values(this.fleet.equipmentTypes).includes(true);

    if((this.isVehicleAmountsSet || this.isTrailerTypesSet || this.isTotalFleetSet || this.isEquipmentTypesSet) && !this.form.invalid) {
      return true;
    }

    return false;
  }

  preset() {
    this.parameters.compositions = {
      vehicleAmounts: Nx3SearchComposition.AND,
      trailerTypes: Nx3SearchComposition.AND,
      totalFleet: Nx3SearchComposition.AND,
      equipmentTypes: Nx3SearchComposition.AND
    };
    this.parameters.composition = Nx3SearchComposition.AND;
  }

  value(): any {
    this.parameters.fleet = this.fleet;
    let value = '';

    if(this.isVehicleAmountsSet) {
      let parameterValue = '';
      const vehicleAmountsSection = 'property_' + this.section.find(section => section.name === 'vehicleAmounts').name + '_label';
      for(const vehicle of this.fleet.vehicleAmounts) {
        if(parameterValue === '') {
          parameterValue = this.translate(vehicleAmountsSection) + ':';
        } else {
          parameterValue += ' ' + this.translate('nx3_filter_' + this.parameters.compositions.vehicleAmounts) + ' ';
        }
        if(vehicle.totalVehicles) {
          if(vehicle.operator) {
            parameterValue += ' ' + this.translate('nx3_filter_' + vehicle.operator) + ' ' + vehicle.totalVehicles;
          } else {
            parameterValue += ' ' + vehicle.totalVehicles;
          }
        }
        if(vehicle.vehicleType) {
          parameterValue += ' ' + this.translate('FleetVehicleType_' + vehicle.vehicleType);
        }
        if(vehicle.driveType) {
          parameterValue += ' ' + this.translate('FleetDriveType_' + vehicle.driveType);
        }
      }
      value += parameterValue;
    }

    if(this.isTrailerTypesSet) {
      let parameterValue = '';
      const trailerTypesSection = 'property_' + this.section.find(section => section.name === 'trailerTypes').name + '_label';
      for(const key of Object.keys(this.fleet.trailerTypes)) {
        if(this.fleet.trailerTypes[key]) {
          if(parameterValue === '') {
            parameterValue = this.translate(trailerTypesSection) + ': ' + this.translate('property_' + key + '_label');
          } else {
            parameterValue += ' ' + this.translate('nx3_filter_' + this.parameters.compositions.trailerTypes) + ' ' + this.translate('property_' + key + '_label');
          }
        }
      }
      if(value === '') {
        value += parameterValue;
      } else {
        value += ' ' + this.translate('nx3_filter_' + this.parameters.composition) + ' ' + parameterValue;
      }
    }

    if(this.isTotalFleetSet) {
      const totalFleetSection = 'property_' + this.section.find(section => section.name === 'totalFleet').name + '_label';
      if(value === '') {
        value += this.translate(totalFleetSection) + ': ' + this.translate('nx3_filter_' + this.fleet.totalFleetOperator) + ' ' + this.fleet.totalFleet;
      } else {
        value += ' ' + this.translate('nx3_filter_' + this.parameters.composition) + ' ' + this.translate(totalFleetSection) + ': ' + this.fleet.totalFleet;
      }
    }

    if(this.isEquipmentTypesSet) {
      let parameterValue = '';
      const equipmentTypesSection = 'property_' + this.section.find(section => section.name === 'equipmentTypes').name + '_label';
      for(const key of Object.keys(this.fleet.equipmentTypes)) {
        if(this.fleet.equipmentTypes[key]) {
          if(parameterValue === '') {
            parameterValue = this.translate(equipmentTypesSection) + ': ' + this.translate('property_' + key + '_label');
          } else {
            parameterValue += ' ' + this.translate('nx3_filter_' + this.parameters.compositions.equipmentTypes) + ' ' + this.translate('property_' + key + '_label');
          }
        }
      }
      if(value === '') {
        value += parameterValue;
      } else {
        value += ' ' + this.translate('nx3_filter_' + this.parameters.composition) + ' ' + parameterValue;
      }
    }

    return value;
  }

  private translate(value: string): string {
    return this.nx3.i18n.translate.instant(value);
  }
}
