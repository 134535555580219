import { Component, ComponentRef, Input, OnInit } from '@angular/core';
import { Nx3Entity } from '@nx3/nx3-client';
import {
  ButtonModel,
  EntitySelectionCommand,
  ModalComponent,
  ModalComponentCommand,
  Nx3Api,
  ReferenceComponentModel,
  SelectionType
} from '@nx3/nx3-core-ui';
import { BehaviorSubject, take } from 'rxjs';

@Component({
  selector: 'app-finish-tender-modal',
  templateUrl: './finish-tender-modal.component.html',
  styleUrls: ['./finish-tender-modal.component.scss'],
})
export class FinishTenderModalComponent implements OnInit {

  server: string;
  tender: Nx3Entity;
  tenderBidModel: ReferenceComponentModel;
  finishWithWinner: boolean;
  informLosers = true;
  closingNote = '';
  static disableButton: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  static asModal(
    server: string,
    tender: Nx3Entity,
    tenderBidModel: ReferenceComponentModel,
  ): ModalComponentCommand {
    let modal: ModalComponent;
    let component: FinishTenderModalComponent;

    return new ModalComponentCommand(
      FinishTenderModalComponent,
      { server, tender, tenderBidModel },
      'money',
      'nx3_button_finish_tender',
      [
        new ButtonModel('nx3_button_cancel', 'cancel', (event: any) => {
          modal.dismiss(null);
        })
        .withCssClass('btn btn-sm btn-secondary')
        .withOverflow(false),

        new ButtonModel('nx3_button_next', 'next', (event: any) => {
          component.selectWinner();
          modal.close(null);
        })
        .withDisabled(this.disableButton)
        .withCssClass('btn btn-sm btn-warning')
        .withOverflow(false),
      ],
      undefined,
      (m: ModalComponent) => {
        modal = m;
      },
      (c: ComponentRef<any>) => {
        component = c.instance;
      },
      (param: any) => {},
      (param: any) => {},
      { size: 'lg', backdrop: 'static' }
    );

  }

  constructor(public nx3: Nx3Api) {}

  ngOnInit(): void {
    FinishTenderModalComponent.disableButton.next(true);
  }

  selected() {
    FinishTenderModalComponent.disableButton.next(this.finishWithWinner == undefined);
  }

  selectWinner() {
    if (this.finishWithWinner) {
      this.nx3.modal.selectEntity(
        new EntitySelectionCommand(
          this.tenderBidModel.resourceConfig,
          this.tenderBidModel.resourceModel,
          SelectionType.SINGLE,
          (selection: Nx3Entity[]) => {
            this.finishTender(selection[0].id);
          },
          undefined,
          { tenderId: this.tender.id }
        )
      );
    } else {
      this.finishTender(null);
    }

  }

  finishTender(tenderBidId) {
    this.nx3.spinner.show();
    let url = '/api/tender/finish/' + this.tender.id + '?informLosers=' + this.informLosers;
    if(tenderBidId) {
      url += '&tenderBidId=' + tenderBidId;
    }

    this.nx3.client.rest
      .post(this.server, url, this.closingNote)
      .pipe(take(1))
      .subscribe(() => {
        this.nx3.spinner.hide();
        this.nx3.navigation.reload();
        this.nx3.toast.success(
          this.nx3.i18n.translate.instant(
            'nx3_label_finish_tender_success'
          )
        );
      });
  }

}
