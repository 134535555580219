<div [formGroup]="formGroup">
  <div class="input-group input-group-sm"
    *ngIf="options && options.length > 0">
    <input class="form-control form-control-sm"
      type="text"
      name="region"
      [typeaheadMinLength]="2"
      [typeahead]="options"
      [(ngModel)]="value.key"
      [required]="field.required"
      [ngModelOptions]="{ standalone: true }"
      [typeaheadOptionsLimit]="options.length"
      typeaheadScrollable="true"
      typeaheadOptionsInScrollableView="15"
      typeaheadOptionField="description"
      (typeaheadOnSelect)="onSelect($event)"
      (blur)="onBlur()"
      container="body" />
    <span class="input-group-text nx3-clickable"
      (click)="selectRegion()"
      [ngbTooltip]="'nx3_button_select' | translate">
      <i class="{{ nx3.icons.css('map') }}"></i>
    </span>
  </div>
</div>