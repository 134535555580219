import { Nx3DataType } from '@nx3/nx3-client';
import { DetailConfig, EditorEvent, ListConfig, MediaFileExtension, Nx3Api } from '@nx3/nx3-core-ui';
import { CarrierService } from '../../../../shared/src/lib/carrier.service';
export class CustomMediafileExtension extends MediaFileExtension {
  constructor(public nx3: Nx3Api) {
    super(nx3);
  }

  configureDetail(config: DetailConfig): void {
    super.configureDetail(config);
    config.enableDuplicate = false;
  }

  configureList(config: ListConfig): void {
    super.configureList(config);
    config.enableDuplicate = false;
  }

  onEditorInit(event: EditorEvent): void {
    super.onEditorInit(event);
    const categoryField = event.component.metadata.getFieldByName('category');
    categoryField.type = Nx3DataType.CHOICE;
    categoryField.options = [];
    for (const category of this.nx3.injector.get(CarrierService)
      .mediafileCategories) {
      categoryField.options.push({
        key: category,
        label: this.nx3.i18n.translate.instant(category + '_label'),
      });
    }
  }
}
