import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Nx3Api } from '@nx3/nx3-core-ui';
import { Nx3Entity, ApplicationEventType } from '@nx3/nx3-client';

@Component({
  selector: 'app-documents-share-charts',
  templateUrl: './documents-share-charts.component.html',
  styleUrls: ['./documents-share-charts.component.scss'],
})
export class DocumentsShareChartsComponent implements OnInit, OnDestroy{
  @Input() server: string;
  showChart = true;

  timelineChartConfig = {
    chart: {
      events: {
        load: function() {
          this.yAxis[1].update();
        }
      },
    },
    rangeSelector: {
      allButtonsEnabled: true,
      buttons: [
        {
          type: 'ytd',
          count: 1,
          text: this.nx3.i18n.translate.instant('nx3_label_month'),
          dataGrouping: {
            approximation: 'avg',
            forced: true,
            units: [['month', [1]]],
          },
          preserveDataGrouping: true,
        },
        {
          type: 'year',
          count: 3,
          text: this.nx3.i18n.translate.instant('nx3_label_quarter'),
          dataGrouping: {
            approximation: 'avg',
            forced: true,
            units: [['month', [3]]],
          },
          preserveDataGrouping: true,
        },
        {
          type: 'all',
          text: this.nx3.i18n.translate.instant('nx3_label_year'),
          dataGrouping: {
            approximation: 'avg',
            forced: true,
            units: [['year', [1]]],
          },
          preserveDataGrouping: true,
        },
      ],
      buttonTheme: {
        width: 60,
      },
      selected: 0,
    }
  };

  constructor(public nx3: Nx3Api) {}

  ngOnInit(): void {
    this.reloadChart();

    this.nx3.client.events.on(ApplicationEventType.LANGUAGE_CHANGED, () => {
      this.reloadChart();
    }, this);
  }

  reloadChart(): void {
    this.showChart = false;
    setTimeout(() => {
      this.showChart = true;
    });
  }

  ngOnDestroy(): void {
    this.nx3.client.events.unregister(ApplicationEventType.LANGUAGE_CHANGED, this);
  }

  processor(chart: Nx3Entity): void {
    if (chart) {
      for (const yAxis of chart.chartConfig.yAxis) {
        yAxis.title.text = this.nx3.i18n.translate.instant(yAxis.title.text);
      }
    }
  }
}
