import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Nx3BeanModel } from '@nx3/nx3-client';

import { Nx3DataType, Nx3Entity, Nx3FieldModel, Nx3FormRow, Nx3FormSection } from '@nx3/nx3-client';
import { AlertDialogCommand, EditorComponent, FormBehavior, Nx3Api } from '@nx3/nx3-core-ui';
import { WarnAboutCarrierResetModalComponent } from './warn-about-carrier-reset-modal/warn-about-carrier-reset-modal.component';

@Injectable({
  providedIn: 'root',
})
export class CarrierService {
  currentDepartmentId: string;
  resetRelevantFields: Map<string, any> = new Map<string, any>();
  carrierResetWarningHasBeenAccepted: boolean;

  mediafileCategories = ['mandatory-documents', 'qualifications', 'information'];

  constructor(private nx3: Nx3Api) {}

  resetState(): void {
    this.carrierResetWarningHasBeenAccepted = false;
    this.currentDepartmentId = undefined;
  }

  initEditor(editor: EditorComponent): void {
    editor.config.preventDirtynessCheck = false;

    this.nx3.forms.setSectionCollapsible('property_invoiceAddress_label', editor.metadata, true);

    const resetRelevantFieldNames = [
      'actor.name',
      'actor.legalForm',
      'actor.vatId',
      'otherVatId',
      'actor.address.street',
      'actor.address.country',
      'actor.address.zipCode',
      'actor.address.city',
      'invoiceAddressPostOffice.postOfficeBox',
      'invoiceAddressPostOffice.zipCode',
    ];

    this.currentDepartmentId = editor.entity.department?.referenceId;
    for (const resetRelevantFieldName of resetRelevantFieldNames) {
      const field = editor.metadata.getFieldByName(resetRelevantFieldName);
      this.resetRelevantFields.set(resetRelevantFieldName, field.getValue(editor.entity));
    }
  }

  editorRendered(editor: EditorComponent): void {
    this.nx3.forms.addValidators('actor.vatId', editor.metadata, editor.form, Validators.required);

    this.nx3.forms.addValidators(
      'otherVatId',
      editor.metadata,
      editor.form,
      this.nx3.validation.getValidators(editor.metadata.getFieldByName('otherVatId'))
    );

    this.nx3.forms.setRequired('electronicBill.ebillAgreed', editor.metadata, editor.form, true);
  }

  isDirty(property: string, formGroup: UntypedFormGroup): boolean {
    const control = this.nx3.forms.getControl(property, formGroup);
    if (!control) {
      return false;
    }

    return control.dirty;
  }

  setRequired(
    property: string,
    metadata: Nx3BeanModel,
    formGroup: UntypedFormGroup,
    required: boolean,
    updateControl: boolean
  ): AbstractControl {
    const control = this.nx3.forms.getControl(property, formGroup);
    const field = metadata.getFieldByName(property);
    if (!field || !control) {
      return null;
    }
    field.required = required;
    if (required) {
      control.setValidators(Validators.required);
    } else {
      control.clearValidators();
    }
    if (updateControl) {
      control.updateValueAndValidity();
    }
    return control;
  }

  removeValidators(
    property: string,
    metadata: Nx3BeanModel,
    formGroup: UntypedFormGroup,
    validators: ValidatorFn | ValidatorFn[]
  ): AbstractControl {
    const control = this.nx3.forms.getControl(property, formGroup);
    const field = metadata.getFieldByName(property);
    if (!field || !control) {
      return null;
    }
    control.removeValidators(validators);
    control.updateValueAndValidity({ onlySelf: true, emitEvent: false });
    field.required = control.hasValidator(Validators.required);

    return control;
  }

  addValidators(
    property: string,
    metadata: Nx3BeanModel,
    formGroup: UntypedFormGroup,
    validators: ValidatorFn | ValidatorFn[]
  ): AbstractControl {
    const control = this.nx3.forms.getControl(property, formGroup);
    const field = metadata.getFieldByName(property);
    if (!field || !control) {
      return null;
    }
    control.addValidators(validators);
    control.updateValueAndValidity({ onlySelf: true, emitEvent: false });
    field.required = control.hasValidator(Validators.required);

    return control;
  }

  resetValidators(property: string, metadata: Nx3BeanModel, formGroup: UntypedFormGroup): AbstractControl {
    const control = this.nx3.forms.getControl(property, formGroup);
    const field = metadata.getFieldByName(property);
    if (!field || !control) {
      return null;
    }
    control.clearValidators();
    control.addValidators(this.nx3.validation.getValidators(field));
    control.updateValueAndValidity({ onlySelf: true, emitEvent: false });
    field.required = control.hasValidator(Validators.required);

    return control;
  }

  clearValidators(property: string, metadata: Nx3BeanModel, formGroup: UntypedFormGroup): AbstractControl {
    const control = this.nx3.forms.getControl(property, formGroup);
    const field = metadata.getFieldByName(property);
    if (!field || !control) {
      return null;
    }
    control.clearValidators();
    control.updateValueAndValidity({ onlySelf: false, emitEvent: false });
    field.required = control.hasValidator(Validators.required);
    return control;
  }

  setValue(property: string, metadata: Nx3BeanModel, formGroup: UntypedFormGroup, value: any): AbstractControl {
    const control = this.nx3.forms.getControl(property, formGroup);
    const field = metadata.getFieldByName(property);
    if (!field || !control) {
      return null;
    }
    control.setValue(value);
    control.updateValueAndValidity({ onlySelf: true, emitEvent: false });

    return control;
  }

  configureFormBehavior(behavior: FormBehavior, editor: EditorComponent) {
    // collapse section for invoice address if there is no current value
    behavior
      .global((object?: any) => {
        return !object.deviatingBillingAddress;
      })
      .setSectionCollapsed('property_invoiceAddress_label', true);

    // the user has the option to enter a billing address different from the normal address
    behavior
      .when('deviatingBillingAddress', (object?: any) => {
        return !object.deviatingBillingAddress;
      })
      .setSectionCollapsed('property_invoiceAddress_label', true);
    behavior
      .when('deviatingBillingAddress', (object?: any) => {
        return object.deviatingBillingAddress;
      })
      .setSectionCollapsed('property_invoiceAddress_label', false);

    // set cancellationText behavior depending on value of agreed checkbox
    behavior
      .when('electronicBill.ebillAgreed', false)
      .setRequired('electronicBill.ebillCancellationText', true)
      .setDisabled('electronicBill.ebillCancellationText', false)
      .setValidators('electronicBill.ebillEMailAddress', Validators.email);
    behavior
      .when('electronicBill.ebillAgreed', true)
      .clearValue('electronicBill.ebillCancellationText')
      .setRequired('electronicBill.ebillCancellationText', false)
      .setDisabled('electronicBill.ebillCancellationText', true)
      .setValidators('electronicBill.ebillEMailAddress', [Validators.email, Validators.required]);
    behavior
      .when('electronicBill.ebillAgreed', null)
      .clearValue('electronicBill.ebillCancellationText')
      .setRequired('electronicBill.ebillCancellationText', false)
      .setDisabled('electronicBill.ebillCancellationText', true)
      .setValidators('electronicBill.ebillEMailAddress', Validators.email);

    // set worldwide vat id required if there is no valid eu vat id

    behavior
      .when('otherVatId', (object: any) => {
        return object.otherVatId?.length > 0 && this.isDirty('otherVatId', editor.form);
      })
      .run((object: any) => {
        if (!object.actor.vatId.doesNotExist) {
          object.actor.vatId.country = null;
          object.actor.vatId.countryCode = null;
          object.actor.vatId.vatNumber = null;
          object.actor.vatId.doesNotExist = true;
        }
        if (!this.nx3.forms.getControl('otherVatId', editor.form).hasValidator(Validators.required)) {
          this.clearValidators('actor.vatId', editor.metadata, editor.form);
          this.addValidators('otherVatId', editor.metadata, editor.form, Validators.required);
          this.nx3.forms.getControl('actor.vatId', editor.form).setValue(object.actor.vatId);
          this.nx3.forms.getControl('actor.vatId', editor.form).markAsTouched();
        }
      });

    behavior
      .when('otherVatId', (object: any) => {
        return !object.otherVatId && this.isDirty('otherVatId', editor.form) && !object.actor.vatId?.doesNotExist;
      })
      .run((object: any) => {
        this.resetValidators('actor.vatId', editor.metadata, editor.form);
        this.addValidators('actor.vatId', editor.metadata, editor.form, Validators.required);
        this.removeValidators('otherVatId', editor.metadata, editor.form, Validators.required);
      });

    behavior
      .when('actor.vatId', (object: any) => {
        return !object.actor.vatId?.vatNumber && object.actor.vatId?.doesNotExist;
      })
      .run((object: any) => {
        this.clearValidators('actor.vatId', editor.metadata, editor.form);
        this.addValidators('otherVatId', editor.metadata, editor.form, Validators.required);
      });

    behavior
      .when('actor.vatId', (object: any) => {
        return object.actor.vatId?.vatNumber || !object.actor.vatId?.doesNotExist;
      })
      .run((object: any) => {
        object.otherVatId = null;
        this.resetValidators('actor.vatId', editor.metadata, editor.form);
        this.addValidators('actor.vatId', editor.metadata, editor.form, Validators.required);
        this.removeValidators('otherVatId', editor.metadata, editor.form, Validators.required);
        const otherVatIdControl = this.nx3.forms.getControl('otherVatId', editor.form);
        if (otherVatIdControl) {
          this.nx3.forms.getControl('otherVatId', editor.form).setValue(object.otherVatId);
          this.nx3.forms.getControl('otherVatId', editor.form).markAsTouched;
        }
      });

    if (editor.metadata.getFormFieldNames().includes('department')) {
      behavior
        .when('department', (object: any) => {
          if (!this.currentDepartmentId) {
            return false;
          }
          return this.currentDepartmentId !== object.department?.referenceId;
        })
        .run((object: Nx3Entity) => {
          this.currentDepartmentId = object.department?.referenceId;
          this.nx3.modal.alert(new AlertDialogCommand('nx3_label_warning', 'message_department_changed', 'exclamation'));
        });

      if (!this.nx3.client.auth.hasSecurityGroup('CARRIER_GROUP')) {
        behavior
          .when('minimumWageAccepted', (value) => {
            return true;
          })
          .run((object: Nx3Entity) => {
            if (object.minimumWageAccepted) {
              this.addCollaborationWarning(editor);
            } else {
              this.hideCollaborationWarning(editor);
            }
          });

        behavior
          .when('requirementProfileAccepted', (value) => {
            return true;
          })
          .run((object: Nx3Entity) => {
            if (object.requirementProfileAccepted) {
              this.addCollaborationWarning(editor);
            } else {
              this.hideCollaborationWarning(editor);
            }
          });

        behavior
          .when('environmentalManagementAccepted', (value) => {
            return true;
          })
          .run((object: Nx3Entity) => {
            if (object.environmentalManagementAccepted) {
              this.addCollaborationWarning(editor);
            } else {
              this.hideCollaborationWarning(editor);
            }
          });
      }
    }

    this.resetRelevantFields.forEach((value: any, key: string) => {
      behavior
        .when(key, (object: any) => {
          if (this.carrierResetWarningHasBeenAccepted) {
            return false;
          }
          const objectValue = editor.metadata.getFieldByName(key).getValue(object);
          const condition = (value !== objectValue || key === 'actor.vatId') && object.id && this.isDirty(key, editor.form);
          return condition;
        })
        .run((object: Nx3Entity) => {
          const model = editor.getModel();
          const objectValue = editor.metadata.getFieldByName(key).getValue(object);
          if (this.resetRelevantFields.get(key) || objectValue) {
            this.nx3.modal.component(
              WarnAboutCarrierResetModalComponent.asModal(this.nx3, model, editor, () => {
                this.carrierResetWarningHasBeenAccepted = true;
              })
            );
          }
          this.resetRelevantFields.set(key, objectValue);
        });
    });
  }

  /**
   * Show a warning if the user has accepted at least one collaboration term
   */
  addCollaborationWarning(editor: EditorComponent) {
    const existingField = editor.metadata.getFieldByName('addAttachmentWarning');

    if (!editor.form.touched) {
      // Do not show warning if form is pristine.
      return;
    }

    if (existingField) {
      this.nx3.forms.setHidden('addAttachmentWarning', editor.metadata, editor.form, false);
      return;
    } else {
      const field = new Nx3FieldModel();
      field.name = 'addAttachmentWarning';
      field.type = Nx3DataType.COMPONENT;
      (field.componentDescriptor = 'alert:miwageText:nx3_message_proof_warning:danger'), (field.hidden = false);
      field.labelHidden = true;
      field.span = 2;
      const targetSection: Nx3FormSection = editor.metadata.getFormSectionByLabel('section_collaboration');
      const index = editor.metadata.form.sections.findIndex((section) => section.key === targetSection.key);
      // create new row at start of section.
      const newRow = new Nx3FormRow();
      newRow.fields = [];
      targetSection.rows.unshift(newRow);
      editor.metadata.addFormField(field, index, 0);
    }
  }

  /**
   * Hide the collaboration warning if all three checkboxes are unchecked.
   */
  hideCollaborationWarning(editor: EditorComponent) {
    if (
      editor.metadata.getFieldByName('addAttachmentWarning') &&
      !editor.entity.minimumWageAccepted &&
      !editor.entity.requirementProfileAccepted &&
      !editor.entity.environmentalManagementAccepted
    ) {
      this.nx3.forms.setHidden('addAttachmentWarning', editor.metadata, editor.form, true);
    }
  }
}
