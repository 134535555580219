import { Component } from '@angular/core';
import { IntegerFieldWidgetComponent, Nx3Api } from '@nx3/nx3-core-ui';
import { take } from 'rxjs';

@Component({
  selector: 'app-price-difference-field',
  templateUrl: './price-difference-field.component.html',
  styleUrls: ['./price-difference-field.component.scss']
})
export class PriceDifferenceFieldComponent extends IntegerFieldWidgetComponent {

  constructor(public nx3: Nx3Api) {
    super(nx3);
  }

  takeOverPrice() {
    const tenderId = this.field.parameters.tender.id;
    this.nx3.client.rest
      .post(this.resourceConfig.server, '/api/tender/takeoverprice/' + tenderId + '/' + this.object.id, {})
      .pipe(take(1))
      .subscribe(
        () => {
          this.nx3.spinner.hide();
          this.nx3.navigation.reload();
          this.nx3.toast.success(
            this.nx3.i18n.translate.instant('nx3_message_save_successful')
          );
        },
        (error: any) => {
          this.nx3.errorhandler.alert(error);
        }
      );
  }

}
