import {
  DetailConfig,
  DetailEvent,
  FormlayoutComponent,
  Nx3Api,
  ResourceExtension,
  nestedConfig
} from '@nx3/nx3-core-ui';
import { PriceDifferenceFieldComponent } from '../components/price-difference-field/price-difference-field.component';

export class TenderBidExtension extends ResourceExtension {
  constructor(public nx3: Nx3Api) {
    super('tenderbid', nx3);
  }

  configureDetail(config: DetailConfig): void {
    config.layout
      .rootLayout()
      .row()
      .componentPresets(this.detailLayout().topPresets)
      .up()
      .row()
      .nestedColumn(12)
      .component(
        FormlayoutComponent,
        nestedConfig(false).mapModel('entity', 'object')
      )
      .exitNesting()
      .up();
  }

  onDetailInit(event: DetailEvent): void {
    const priceDifference = event.component.metadata.getFieldByName('priceComparisonInPercentage');
    if (priceDifference) {
      priceDifference.componentType = PriceDifferenceFieldComponent;
      priceDifference.readonly = true;
    }
  }
}
