// tslint:disable-next-line: max-line-length
import { ApplicationEvent, ApplicationEventType, Nx3DataType } from '@nx3/nx3-client';
import {
  ActivityStreamComponent,
  ApplicationExtension,
  CmsChartComponent,
  ContentPanelConfig,
  DashboardConfig,
  DashboardLayoutService,
  FieldWidgetFactory,
  ListAdapterComponent,
  Nx3Api,
  Nx3ApplicationConfig,
  QuicklaunchComponent,
  SpecificationService,
  nestedConfig,
  newConfig,
} from '@nx3/nx3-core-ui';
import { ProfileConfig } from '@nx3/nx3-core-ui/lib/nx3-components/extension/profile-config';
import { of, take } from 'rxjs';
import { DocumentsShareChartsComponent } from './components/documents-share-charts/documents-share-charts.component';
import { FleetSpecificationComponent } from './components/fleet-specification/fleet-specification.component';
/**
 * Configures all global aspects of the NX3 frontend application
 *
 * @author daniel.peer(at)serie-a.de
 */
export class AppExtension extends ApplicationExtension {
  server: string;

  constructor(
    public nx3: Nx3Api,
    public fieldWidgetFactory: FieldWidgetFactory,
    public dashboardLayout: DashboardLayoutService
  ) {
    super(nx3, fieldWidgetFactory, dashboardLayout);
  }

  /**
   * Configuration callback for global settings
   * @param config
   */
  configureGlobals(config: Nx3ApplicationConfig) {
    this.server = config.registrationServer;
    this.nx3.list.propagateCellClickTypes.push(Nx3DataType.ELEMENT_COLLECTION)
    config.maskLanguages = false;
    config.maskCountries = true;
    config.quicklaunch.push(
      { resourceName: 'nx3usertask', server: this.server },
      { resourceName: 'carrier', server: this.server },
      { resourceName: 'carriercandidate', server: this.server },
      { resourceName: 'department', server: this.server }
    );

    config.gridComponentExists.set(
      'BusinesspartnerEmployeesComponent',
      (model: any) => {
        if (
          model.entity.resourceType !==
          'de.seriea.nx3.transa.businesspartner.carrier.Carrier'
        ) {
          return true;
        }
        return model.entity.duplicateChecked;
      }
    );

    config.gridComponentExists.set('EmployeeDetailComponent', (model: any) => {
      return !this.nx3.client.auth.hasSecurityGroup('DISPONENT_GROUP');
    });

    config.buttonExists.set('create_forbidden', (model: any) => {
      return !this.nx3.client.auth.hasSecurityGroup('DISPONENT_GROUP');
    });

    config.buttonExists.set('cancel', (model: any) => {
      if (model?.label === 'nx3_button_cancel_qualification') {
        return !this.nx3.client.auth.hasSecurityGroup('DISPONENT_GROUP');
      }
      return true;
    });

    this.nx3.client.events.on(
      ApplicationEventType.LOGIN,
      (event: ApplicationEvent) => {
        const storageKey = this.nx3.client.storage.getClientBoundKey('regions_name_list');

        if(!this.nx3.client.storage.session.exists(storageKey)) {
          const url = '/api/customregion/regionlist';
          this.nx3.client.rest.get(this.server, url).pipe(take(1)).subscribe((regions: any[]) => {
            regions.sort((a, b) => a.description.localeCompare(b.description));
            this.nx3.client.storage.session.set(
              storageKey,
              JSON.stringify(regions)
            );
          })
        }
      }, this);

    this.fieldWidgetFactory.registerSpecificationType(
      'fleet',
      FleetSpecificationComponent
    );

    const specService = this.nx3.injector.get(SpecificationService);
      specService.registerSupportSpecification(
      'de.seriea.nx3.transa.businesspartner.fleet.Fleet',
      {
        fieldName: 'specification.fleet',
        label: 'fleet',
        icon: 'truck-loading',
        specificationClass:
          'de.seriea.nx3.transa.businesspartner.fleet.FleetSupportSpecification',
      }
    );
  }

  /**
   * Configure the dashboard page, which is available to all authenticated users
   * @param config
   */
  configureDashboard(config: DashboardConfig) {
    this.dashboardLayout.createPresets(this.nx3);

    config.layout
      .rootLayout()
      .withPadding(2, 2)
      .withUseContainer(true)
      .row()
      .nestedColumn(6)
      .component(
        QuicklaunchComponent,
        nestedConfig(false)
          .withContentPanel(
            new ContentPanelConfig('rocket', 'nx3_quicklaunch_dashlet')
          )
          .withExists((model: any) => {
            return of(this.nx3.config.quicklaunch.length > 0);
          })
      )
      .component(
        CmsChartComponent,
        newConfig(12, true).withUseContainer(true),
        {
          server: 'rs1',
          key: 'carrier_per_source',
          config: {
            xAxis: {
              labels: {
                enabled: true,
                formatter: function (value: any) {
                  const data = value.chart.series[0].options.data[value.value];
                  return data.name + ' (' + data.y + ')';
                },
                style: {
                  fontFamily: 'Roboto, sans-serif',
                },
              },
            },
          },
        }
      )
      .component(
        CmsChartComponent,
        newConfig(12, true).withUseContainer(true),
        {
          server: 'rs1',
          key: 'carrier_lifecycle',
        }
      )
      .component(
        CmsChartComponent,
        newConfig(12, true)
          .withUseContainer(true)
          .withExists((model: any) => {
            return of(
              this.nx3.client.auth.hasOneSecurityGroupOf([
                'DISPONENT_GROUP',
                'CARRIER_ADMINISTRATOR_GROUP',
              ])
            );
          }),
        {
          server: 'rs1',
          key: 'carrier_selfservice_department',
        }
      )
      .component(
        CmsChartComponent,
        newConfig(12, true)
          .withUseContainer(true)
          .withExists((model: any) => {
            return of(
              this.nx3.client.auth.hasOneSecurityGroupOf([
                'NX3_GROUP',
                'OPERATOR_ADMIN_GROUP',
              ])
            );
          }),
        {
          server: 'rs1',
          key: 'carrier_selfservice_operator',
        }
      )
      .component(
        DocumentsShareChartsComponent,
        newConfig(12, true)
          .withUseContainer(true)
          .withExists((model: any) => {
            return of(
              this.nx3.client.auth.hasOneSecurityGroupOf([
                'NX3_GROUP',
                'OPERATOR_ADMIN_GROUP',
              ])
            );
          }),
        {
          server: 'rs1',
        }
      )
      .exitNesting()
      .nestedColumn(6)
      .component(
        ListAdapterComponent,
        newConfig(12).withContentPanel(
          new ContentPanelConfig('tasks', 'nx3_button_tasks', 'pb-2')
        ),
        { server: this.server, resource: 'nx3usertask' }
      )
      .component(
        ActivityStreamComponent,
        nestedConfig(false)
          .withContentPanel(
            new ContentPanelConfig('stream', 'nx3_label_activity_stream')
          )
          .withExists((model: any) => {
            return of(
              this.nx3.client.auth.hasAuthority('ACTIVITY_STREAM_READ')
            );
          }),
        { height: 600, server: this.nx3.config.registrationServer }
      )
      .exitNesting();
  }

  configureProfile(config: ProfileConfig) {
    this.nx3.client.events.on(
      ApplicationEventType.PARTNER_RETRIEVED,
      (event: ApplicationEvent) => {
        config.enableOrganizationEditing =
          this.nx3.client.auth.hasOneSecurityGroupOf([
            'OPERATOR_ADMIN_GROUP',
            'NX3_GROUP',
          ]);
      },
      this
    );
  }
}
