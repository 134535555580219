<div *ngIf="error" class="nx3-fade-in">
  <ngb-alert
    type="danger"
    [dismissible]="false"
    class="p-2 nx3-clickable"
    (click)="logout()"
  >
    <strong>{{ 'nx3_label_error' | translate }}</strong>
    <p class="mb-0">
      {{ error | translate }}
    </p>
  </ngb-alert>
</div>

<div *ngIf="department" class="nx3-fade-in">
  <h5 class="nx3-color-primary mb-3 mt-3">{{ department.actor.name }}</h5>

  <div class="d-flex border rounded p-3 row" style="overflow-wrap: anywhere">
    <div class="align-self-center col-3">
      <div class="d-flex">
        <h1 class="align-self-center">
          <nx3-icon icon="phone" styles="nx3-color-primary me-3"></nx3-icon>
        </h1>
        <div>
          <h5>{{ 'department_contact_phone' | translate }}</h5>
          <h5 *ngIf="department.actor.communication?.phoneNumber">
            +{{ department.actor.communication?.phoneNumber }}
          </h5>
          <h6 *ngIf="!department.actor.communication?.phoneNumber">
            {{ 'department_contact_no_data' | translate }}
          </h6>
        </div>
      </div>
    </div>

    <div class="align-self-center col-4">
      <div class="d-flex">
        <h1 class="align-self-center">
          <nx3-icon icon="envelope" styles="nx3-color-primary me-3"></nx3-icon>
        </h1>
        <div>
          <h5>{{ 'department_contact_mail' | translate }}</h5>
          <h5 *ngIf="department.actor.communication?.emailWork">
            <a href="mailto:{{ department.actor.communication?.emailWork }}">{{
              department.actor.communication?.emailWork
            }}</a>
          </h5>
          <h6 *ngIf="!department.actor.communication?.emailWork">
            {{ 'department_contact_no_data' | translate }}
          </h6>
        </div>
      </div>
    </div>

    <div class="align-self-center col-5">
      <div class="d-flex">
        <h1 class="align-self-center">
          <nx3-icon icon="map-marker" styles="nx3-color-primary me-3"></nx3-icon>
        </h1>
        <div>
          <h5>{{ 'department_contact_address' | translate }}</h5>
          <h5 *ngIf="department.address?.street">
            {{ department.address.street }}
            {{ department.address.zipCode }} {{ department.address.city }}
          </h5>
          <h6 *ngIf="!department.address?.street">
            {{ 'department_contact_no_data' | translate }}
          </h6>
        </div>
      </div>
    </div>
  </div>
</div>
