<table class="table">
  <thead>
    <tr>
      <th scope="col">{{ 'property_hitProbability_label' | translate }}</th>
      <th scope="col">{{ 'property_companyName_label' | translate }}</th>
      <th scope="col">{{ 'property_firstName_label' | translate }}</th>
      <th scope="col">{{ 'property_lastName_label' | translate }}</th>
      <th scope="col">{{ 'property_description_label' | translate }}</th>
      <th scope="col">{{ 'property_sanctionReason_label' | translate }}</th>
      <th scope="col">{{ 'property_attribution_label' | translate }}</th>
      <th scope="col">{{ 'property_fileNumber_label' | translate }}</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>{{ object.hitProbability }}</td>
      <td>{{ object.companyName }}</td>
      <td>{{ object.lastName }}</td>
      <td>{{ object.firstName }}</td>
      <td>{{ object.description }}</td>
      <td>{{ object.sanctionReason }}</td>
      <td>{{ object.attribution }}</td>
      <td>{{ object.fileNumber }}</td>
    </tr>
  </tbody>
</table>
