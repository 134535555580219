import { NgModule } from '@angular/core';
import { Nx3CmsModule, Nx3ComponentsModule } from '@nx3/nx3-core-ui';
import { SharedModule } from '../../../../shared/src/lib/shared.module';


/**
 * Custom components specialized for this application
 */
@NgModule({
  declarations: [],
  imports: [
    Nx3ComponentsModule,
    Nx3CmsModule,
    SharedModule
  ]
})
export class PagesModule { }
