import { library } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/pro-regular-svg-icons';
import {
  LifecycleTimelineComponent,
  Nx3ApplicationConfig,
  ThemeSwitchComponent,
} from '@nx3/nx3-core-ui';
import { environment } from './../environments/environment';

library.add(far as any);

Nx3ApplicationConfig.codemirrorTheme = 'monokai';

const logo = 'assets/' + ThemeSwitchComponent.getTheme() + '_nx3.svg';

// configures the global settings the NX3 Core UI
export const applicationConfig = new Nx3ApplicationConfig(
  logo,
  logo,
  'COMeT',
  environment.nx3Client,
  'fa-',
  'far',
  Object.keys((library as any).definitions.far)
);

applicationConfig.languages = ['en', 'de'];

// enables websocket support
//applicationConfig.websocketBroker = 'rs1';

// enables development mode, which enables some debug renderings, useful for development
applicationConfig.devMode = false;

applicationConfig.lifecycleDetailComponentType = LifecycleTimelineComponent;
applicationConfig.lifecycleHistoryComponentType = LifecycleTimelineComponent;
