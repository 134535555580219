import { Component } from '@angular/core';
import { AbstractFieldWidgetComponent, Nx3Api } from '@nx3/nx3-core-ui';

/**
 * Implements userdetails field for persons/employees
 *
 * @author daniel.peer(at)serie-a.de
 */
@Component({
  selector: 'app-custom-userdetails-field',
  templateUrl: './custom-userdetails-field.component.html',
  styleUrls: ['./custom-userdetails-field.component.scss'],
})
export class CustomUserdetailsFieldComponent extends AbstractFieldWidgetComponent {
  userDetails: any;

  constructor(public nx3: Nx3Api) {
    super(nx3);
  }

  init() {
    super.init();
    this.userDetails = this.object.virtual.userDetails;
  }

}
