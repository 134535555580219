import { Nx3BeanModel, Nx3DataType, Nx3Entity, Nx3FieldModel, Nx3FormRow } from '@nx3/nx3-client';
import {
  ButtonModel,
  ButtonRegistration,
  CommonNames,
  ConfirmDialogCommand,
  ContentPanelConfig,
  DetailConfig,
  DetailEvent,
  EditorComponent,
  EditorEvent,
  EntityComponentModel,
  FilterSelectionEvent,
  FormlayoutComponent,
  ListAdapterComponent,
  Nx3Api,
  ReferenceListConfig,
  ReferenceListEvent,
  ResourceExtension,
  fromToDate,
  nestedConfig,
  newConfig
} from '@nx3/nx3-core-ui';
import { HiddenNutsRegionsComponent } from 'projects/shared/src/lib/hidden-nuts-regions/hidden-nuts-regions.component';
import { SelectRegionFieldComponent } from 'projects/shared/src/lib/select-region-field/select-region-field.component';
import { BehaviorSubject, of, take } from 'rxjs';
import { FinishTenderModalComponent } from '../components/finish-tender-modal/finish-tender-modal.component';
import { PriceDifferenceFieldComponent } from '../components/price-difference-field/price-difference-field.component';

export class TenderExtension extends ResourceExtension {
  constructor(public nx3: Nx3Api) {
    super('tender', nx3);
  }

  configureDetail(config: DetailConfig): void {
    const configOverride = {
      containerClass: 'p-2',
      enableFilter: true,
      enableSearch: true,
      pagination: {
        changeable: true,
      },
      table: {
        height: '100%',
        stickyHeader: true,
      },
      filter: {
        hiddenFilterFields: ['filterType', 'references'],
      },
    };

    config.layout
      .rootLayout()
      .row()
      .componentPresets(this.detailLayout().topPresets)
      .up()
      .row()
      .nestedColumn(12)
      .component(
        FormlayoutComponent,
        nestedConfig(false).mapModel('entity', 'object')
      )
      .exitNesting()
      .up()
      .row()
      .nestedColumn(12)
      .component(
        ListAdapterComponent,
        newConfig(12)
          .withContentPanel(
            new ContentPanelConfig('truck', 'carrier_label', 'pb-2')
          )
          .withExists((model: EntityComponentModel) => {
            return of(model.entity.limitedCarrier);
          }),
        {
          server: 'rs1',
          resource: 'carrier',
          mode: CommonNames.DETAIL,
          configOverride: configOverride,
        }
      )
      .up()
      .row()
      .nestedColumn(12)
      .componentPresets(this.detailLayout().bottomPresets)
      .exitNesting()
      .up();
  }

  onBeforeSave(entity: Nx3Entity): void {
    for(const relation of entity.relations) {
      delete relation.regionFrom.area;
      delete relation.regionTo.area;
    }
  }

  onDetailInit(event: DetailEvent): void {
    const server = event.component.resourceConfig.server;

    this.createFromToMapField(event.component.metadata);

    const tenderAction = (action: string, tenderBidId?: string) => {
      this.nx3.spinner.show();
      const url = '/api/tender/' + action + '/' + event.component.entity.id;

      this.nx3.client.rest
        .post(server, url, {})
        .pipe(take(1))
        .subscribe(() => {
          this.nx3.spinner.hide();
          this.nx3.navigation.reload();
          this.nx3.toast.success(
            this.nx3.i18n.translate.instant(
              'nx3_label_' + action + '_tender_success'
            )
          );
        }, (error) => {
          this.nx3.errorhandler.alert(error);
        });
    };

    event.component.registerButton(
      new ButtonRegistration(
        new ButtonModel('nx3_button_publish_tender', 'publish_tender', () => {
          tenderAction('publish');
        })
          .withExists(
            new BehaviorSubject(event.component.entity.state?.key === 'CREATED')
          )
          .withOverflow(false)
          .withIcon('folder-open')
          .withHotkey(this.nx3.hotkeys.createTrigger('t'))
          .withTooltip('nx3_button_publish_tender'),
        DetailConfig.BUTTON_GROUP_TOP
      )
    );

    event.component.registerButton(
      new ButtonRegistration(
        new ButtonModel('nx3_button_close_tender', 'close_tender', () => {
          this.nx3.modal.confirm(
            new ConfirmDialogCommand(
              'nx3_button_close_tender',
              'nx3_message_close_tender',
              () => {
                tenderAction('close');
              }
            )
          );
        })
          .withExists(
            new BehaviorSubject(
              event.component.entity.state?.key === 'PUBLISHED'
            )
          )
          .withOverflow(false)
          .withIcon('folder-open')
          .withHotkey(this.nx3.hotkeys.createTrigger('t'))
          .withTooltip('nx3_button_close_tender'),
        DetailConfig.BUTTON_GROUP_TOP
      )
    );

    event.component.registerButton(
      new ButtonRegistration(
        new ButtonModel('nx3_button_finish_tender', 'finish_tender', () => {
          const tenderBidModel = event.component.referencingModels.find(
            (r) => r.resourceModel.name === 'tenderbid'
          );

          this.nx3.modal.component(
            FinishTenderModalComponent.asModal(server, event.component.entity, tenderBidModel)
          );
        })
          .withExists(
            new BehaviorSubject(event.component.entity.state?.key === 'CLOSED')
          )
          .withOverflow(false)
          .withIcon('folder')
          .withHotkey(this.nx3.hotkeys.createTrigger('t'))
          .withTooltip('nx3_button_finish_tender'),
        DetailConfig.BUTTON_GROUP_TOP
      )
    );
  }

  onEditorInit(event: EditorEvent): void {
    event.component.metadata.getFieldByName(
      'relations'
    ).listModel.columns.find((c) => c.name === 'regionFrom').componentType = SelectRegionFieldComponent;
    event.component.metadata.getFieldByName(
      'relations'
    ).listModel.columns.find((c) => c.name === 'regionTo').componentType = SelectRegionFieldComponent;

    event.component.metadata.getFieldByName(
      'relations'
    ).listModel.columns[0].parameters = {
      create: false,
      edit: false,
      delete: false,
      selectButton: false,
    };

    event.component.metadata.getFieldByName(
      'relations'
    ).listModel.columns[2].parameters = {
      create: false,
      edit: false,
      delete: false,
      selectButton: false,
    };
  }

  onEditorAfterView(event: EditorEvent): void {
    this.createDateFieldValidator(
      event.component,
      'contractStartAt',
      'contractEndAt'
    );
    this.createDateFieldValidator(
      event.component,
      'tenderStartAt',
      'tenderEndAt'
    );
  }

  createFromToMapField(metadata: Nx3BeanModel) {
    if (!metadata.getFieldByName('fromToMap')) {
      const fromToMap = new Nx3FieldModel();
      fromToMap.name = 'fromToMap';
      fromToMap.type = Nx3DataType.TEXT;
      fromToMap.required = false;
      fromToMap.labelKey = 'fromToMap';
      fromToMap.labelHidden = true;
      fromToMap.componentType = HiddenNutsRegionsComponent;
      fromToMap.readonly = true;
      fromToMap.transientProperty = true;
      fromToMap.span = 4;
      const fromToMapRow = new Nx3FormRow();
      fromToMapRow.fields = ['fromToMap'];
      metadata.addField(fromToMap);
      metadata.getFormSectionByLabel('property_relations_label').rows.push(fromToMapRow);
    }
  }

  create(component: EditorComponent, fromDate: string, toDate: string) {
    const validFromField = component.form.get(fromDate);
    const validToField = component.form.get(toDate);

    validFromField.setValidators([
      validFromField.validator,
      fromToDate(validFromField, validToField),
    ]);
    validToField.setValidators([
      validToField.validator,
      fromToDate(validFromField, validToField),
    ]);
  }

  createDateFieldValidator(
    component: EditorComponent,
    fromDate: string,
    toDate: string
  ) {
    const validFromField = component.form.get(fromDate);
    const validToField = component.form.get(toDate);

    validFromField.setValidators([
      validFromField.validator,
      fromToDate(validFromField, validToField),
    ]);
    validToField.setValidators([
      validToField.validator,
      fromToDate(validFromField, validToField),
    ]);
  }

  onReferenceListDestroy(event: ReferenceListEvent): void {
    this.nx3.client.events.unregister(FilterSelectionEvent.TYPE, this);
  }

  configureReferenceLists(
    referenceListConfig: Map<string, ReferenceListConfig>
  ): void {
    const tenderBidConfig = new ReferenceListConfig();
    tenderBidConfig.enableCreate = false;
    tenderBidConfig.enableEdit = false;
    tenderBidConfig.enableSelection = false;
    tenderBidConfig.table.toolDelete = false;
    tenderBidConfig.table.toolEdit = false;
    referenceListConfig.set('tenderbid', tenderBidConfig);
  }

  onReferenceListInit(event: ReferenceListEvent): void {
    if (event.component.metadata.name === 'TenderBid') {
      const priceDifference = event.component.metadata.getColumnByName('priceComparisonInPercentage');
      if (priceDifference) {
        priceDifference.parameters = {
          tender: event.component.parentModel.entity,
        };
        priceDifference.componentType = PriceDifferenceFieldComponent;
        priceDifference.readonly = true;
      }
    }
  }
}
