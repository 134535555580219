<div *ngIf="activeCat;else loading">
  <form>
    <div class="d-flex flex-row">
      <div style="width: 50px">
        <i
          [ngbTooltip]="'nx3_filter_composition' | translate"
          class="{{ nx3.icons.css('code-merge') }} fa-fw nx3-color-primary"
        ></i>
      </div>
      <div class="me-3">
        <label>
          <input
            type="radio"
            [ngModelOptions]="{ name: 'composition', standalone: false }"
            [(ngModel)]="parameters.composition"
            [value]="'AND'"
          />
          <b class="ms-2" translate="nx3_filter_AND"></b>
        </label>
      </div>
      <div class="">
        <label>
          <input
            type="radio"
            [ngModelOptions]="{ name: 'composition', standalone: false }"
            [(ngModel)]="parameters.composition"
            [value]="'OR'"
          />
          <b class="ms-2" translate="nx3_filter_OR"></b>
        </label>
      </div>
    </div>
  </form>
  <ul ngbNav #nav="ngbNav" class="nav-tabs" [(activeId)]="activeCat">
    <li [ngbNavItem]="cat.name" *ngFor="let cat of section">
      <a ngbNavLink>{{ 'property_' + cat.name + '_label' | translate }}</a>
      <ng-template ngbNavContent>
        <form *ngIf="cat.name !== 'totalFleet'">
          <div class="d-flex flex-row">
            <div style="width: 50px">
              <i
                [ngbTooltip]="'nx3_filter_composition' | translate"
                class="{{ nx3.icons.css('code-merge') }} fa-fw nx3-color-primary"
              ></i>
            </div>
            <div class="me-3">
              <label>
                <input
                  type="radio"
                  [ngModelOptions]="{ name: 'parameters.compositions.' + cat.name, standalone: false }"
                  [(ngModel)]="parameters.compositions[cat.name]"
                  [value]="'AND'"
                />
                <b class="ms-2" translate="nx3_filter_AND"></b>
              </label>
            </div>
            <div class="">
              <label>
                <input
                  type="radio"
                  [ngModelOptions]="{ name: 'parameters.compositions.' + cat.name, standalone: false }"
                  [(ngModel)]="parameters.compositions[cat.name]"
                  [value]="'OR'"
                />
                <b class="ms-2" translate="nx3_filter_OR"></b>
              </label>
            </div>
          </div>
        </form>
        <div class="fieldList pe-3" *ngIf="['trailerTypes', 'equipmentTypes'].includes(cat.name)">
          <div class="row">
            <div *ngFor="let field of cat.fields; let i = index" class="col-6">
              <nx3-form-field
                [form]="form"
                [object]="fleet"
                [metadata]="metadata"
                [formConfig]="formConfig"
                [field]="field"
                [resourceConfig]="resourceConfig"
              ></nx3-form-field>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="cat.name === 'totalFleet'">
          <div class="flex-row d-flex">
            <div>
              <select class="form-control form-control-sm me-2" style="max-width: 110px" [(ngModel)]="fleet.totalFleetOperator">
                <option [value]="operator" *ngFor="let operator of operators">
                  {{ 'nx3_filter_' + operator | translate }}
                </option>
              </select>
            </div>
            <nx3-form-field
              [form]="form"
              [object]="fleet"
              [metadata]="metadata"
              [formConfig]="formConfig"
              [field]="cat.fields[0]"
              [resourceConfig]="resourceConfig"
            ></nx3-form-field>
          </div>
        </div>
        <div *ngIf="cat.name === 'vehicleAmounts'">
          <div class="flex-row d-flex">
            <div class="table-responsive">
              <table class="table table-sm">
                <thead class="nx3-unselectable">
                  <!-- looks strange but gets the operators to the exact position as the entries of the element-collection -->
                  <tr role="row">
                    <th class="">
                      <button class="btn btn-sm invisible">|</button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                <tr role="row" *ngFor="let vehicle of fleet.vehicleAmounts">
                  <td>
                    <select class="form-control form-control-sm me-2 mb-1" style="max-width: 110px" [(ngModel)]="vehicle.operator">
                      <option [value]="operator" *ngFor="let operator of operators" value="{{operators[0]}}">
                        {{ 'nx3_filter_' + operator | translate }}
                      </option>
                    </select>
                  </td>
                </tr>
                </tbody>
            </table>
            </div>
              <nx3-form-field
                [form]="form"
                [object]="fleet"
                [metadata]="metadata"
                [formConfig]="formConfig"
                [field]="cat.fields[0]"
                [resourceConfig]="resourceConfig"
              ></nx3-form-field>
          </div>
        </div>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>

<ng-template #loading>
  <div class="container-fluid text-center m-2">
    <i class="{{ nx3.icons.css('hourglass-half') }} nx3-color-primary me-2"></i>
    <span>{{ 'nx3_list_loading_message' | translate }}</span>
  </div>
</ng-template>
