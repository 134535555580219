import { Component, OnInit } from '@angular/core';
import {
  AbstractFieldWidgetComponent,
  ExtensionService,
  Nx3Api,
} from '@nx3/nx3-core-ui';

@Component({
  selector: 'app-sanction-list-check-information',
  templateUrl: './sanction-list-check-information.component.html',
  styleUrls: ['./sanction-list-check-information.component.scss'],
})
export class SanctionListCheckInformationComponent
  extends AbstractFieldWidgetComponent
  implements OnInit
{
  constructor(public nx3: Nx3Api, protected extensions: ExtensionService) {
    super(nx3);
  }

  ngOnInit(): void {
    this.object = this.getValue();
  }
}
