import { DetailConfig, DetailEvent, ListConfig, ListEvent, Nx3Api, Nx3DocumentExtension, TranslatedTextComponent } from '@nx3/nx3-core-ui';

export class BackofficeDocumentExtension extends Nx3DocumentExtension {
  hideReferenceListNames = ['nx3usertask'];

  constructor(public nx3: Nx3Api) {
    super(nx3);
  }

  onDetailInit(event: DetailEvent): void {
    event.component.metadata.getFieldByName('documentType').componentType = TranslatedTextComponent;
  }

  configureDetail(config: DetailConfig): void {
    super.configureDetail(config);
    config.enableDuplicate = false;
  }

  configureList(config: ListConfig): void {
    super.configureList(config);
    config.enableCreate = false;
    config.enableDuplicate = false;
  }

  onListInit(event: ListEvent): void {
    super.onListInit(event);
    event.component.metadata.getColumnByName('documentType').componentType = TranslatedTextComponent;
  }
}
