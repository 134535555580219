<div class="mb-3">
  <nx3-list
    *ngIf="initialized"
    #list
    [filter]="filter"
    [config]="listConfig"
    [resourceModel]="carrierCandidateResourceModel"
    [resourceConfig]="carrierCandidateResourceConfig"
    [metadata]="carrierCandidateBeanModel"
    [specificationMetadata]="carrierCandidateSpecificationMetadata"
    [deleteConfig]="deleteConfig"
  ></nx3-list>
</div>
