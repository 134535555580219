import { Component, ComponentRef } from '@angular/core';
import {
  ButtonModel,
  EditorComponent,
  EntityComponentModel,
  ModalComponent,
  ModalComponentCommand,
  Nx3Api,
} from '@nx3/nx3-core-ui';

@Component({
  selector: 'app-warn-about-carrier-reset-modal',
  templateUrl: './warn-about-carrier-reset-modal.component.html',
  styleUrls: ['./warn-about-carrier-reset-modal.component.scss'],
})
export class WarnAboutCarrierResetModalComponent {
  static asModal(
    nx3: Nx3Api,
    model: EntityComponentModel,
    editorInstance: EditorComponent,
    resetCarrier: () => void
  ): ModalComponentCommand {
    let modal: ModalComponent;

    return new ModalComponentCommand(
      WarnAboutCarrierResetModalComponent,
      model,
      'triangle-exclamation',
      'tsa_message_reset_carrier_header',
      [
        new ButtonModel('nx3_button_cancel', 'no', (event: any) => {
          modal.dismiss(null);
          editorInstance.config.preventDirtynessCheck = true;
          nx3.navigation.reload();
        })
          .withCssClass('btn btn-sm btn-secondary')
          .withOverflow(false),

        new ButtonModel('nx3_button_ok', 'yes', (event: any) => {
          resetCarrier();
          modal.close(null);
        })
          .withCssClass('btn btn-sm btn-warning')
          .withOverflow(false),
      ],
      undefined,
      (m: ModalComponent) => {
        modal = m;
      },
      (c: ComponentRef<any>) => {},
      (param: any) => {},
      (param: any) => {},
      { size: 'md', backdrop: 'static' }
    );
  }
}
