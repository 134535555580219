import {
  ButtonModel,
  DetailComponent,
  DocumentUploadUserTaskExtension,
  EditorEvent,
  fromToDate,
  ListComponent,
  ListConfig,
  ModalComponent,
} from '@nx3/nx3-core-ui';
import { BehaviorSubject, Subject } from 'rxjs';

export class CustomDocumentUploadUserTaskExtension extends DocumentUploadUserTaskExtension {
  static completeDisabled: Subject<boolean> = new BehaviorSubject<boolean>(
    true
  );

  onEditorAfterView(event: EditorEvent) {
    super.onEditorAfterView(event);

    const contentField =
      event.component.metadata.getFieldByName('document.content');
    contentField.required = true;

    const validFromField = event.component.form
      .get('modelExtension')
      .get('validFrom');
    const validToField = event.component.form
      .get('modelExtension')
      .get('validTo');

    validFromField.setValidators([
      validFromField.validator,
      fromToDate(validFromField, validToField),
    ]);
    validToField.setValidators([
      validToField.validator,
      fromToDate(validFromField, validToField),
    ]);

    const notAdducedReasonField = event.component.metadata.getFieldByName(
      'modelExtension.notAdducedReason'
    );

    if (
      notAdducedReasonField &&
      !notAdducedReasonField.getValue(event.component.entity)
    ) {
      notAdducedReasonField.hidden = true;
      event.component.metadata.form.getSectionsByKey('s0').hiddenEdit = true;
    } else {
      notAdducedReasonField.hidden = false;
    }

    event.component.form.statusChanges.subscribe((status) => {
      CustomDocumentUploadUserTaskExtension.completeDisabled.next(
        !(status === 'VALID')
      );
    });
  }

  configureModal(
    list: ListComponent,
    detail: DetailComponent,
    modalInstance: ModalComponent
  ): void {
    super.configureModal(list, detail, modalInstance);

    const completeButton: ButtonModel = modalInstance.buttons.find(
      (btn) => btn.id === 'complete_task'
    );
    completeButton.withDisabled(
      CustomDocumentUploadUserTaskExtension.completeDisabled
    );
  }

  configureList(config: ListConfig): void {
    config.enableGuidedTour = false;
  }
}
