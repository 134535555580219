import {
  Nx3SearchComposition,
  Nx3SearchCriteria,
  Nx3SearchOperator,
} from '@nx3/nx3-client';
import {
  DynamicComponentConfig,
  ListConfig,
  ListEvent,
  Nx3Api,
  Nx3RegionExtension,
} from '@nx3/nx3-core-ui';
import { NutsRegionsComponent } from '../nuts-regions/nuts-regions.component';

export class CustomRegionExtension extends Nx3RegionExtension {
  constructor(public nx3: Nx3Api) {
    super(nx3);
  }

  onListInit(event: ListEvent): void {
    const criteria = new Nx3SearchCriteria();
    criteria.key = 'classification';
    criteria.operator = Nx3SearchOperator.EQUAL;
    criteria.composition = Nx3SearchComposition.AND;
    criteria.values = ['nuts0'];
    criteria.criteria = [];
    event.component.filter.criteria.criteria = [criteria];
    event.component.filterChanged(event.component.filter);
  }

  configureList(config: ListConfig) {
    this.nx3.client.events.on(
      'setRegion',
      (event) => {
        this.nx3.client.entity
          .findByKey(this.listInstance.resourceConfig, event.data)
          .subscribe((region) => {
            this.listInstance.details(region);
          });
      },
      this
    );

    config.componentExtensions.addEastComponent(
      new DynamicComponentConfig(NutsRegionsComponent, { showInList: true }),
      6
    );
  }
}
