import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractFieldWidgetComponent, Nx3Api } from '@nx3/nx3-core-ui';
import { NutsRegionsComponent } from '../nuts-regions/nuts-regions.component';

@Component({
  selector: 'lib-hidden-nuts-regions',
  templateUrl: './hidden-nuts-regions.component.html',
  styleUrls: ['./hidden-nuts-regions.component.scss'],
})
export class HiddenNutsRegionsComponent extends AbstractFieldWidgetComponent implements OnInit {

  @ViewChild('nuts') nuts: NutsRegionsComponent;
  hiddenMap = true;

  constructor(public nx3: Nx3Api) {
    super(nx3);
  }

  setMapVisible() {
    this.hiddenMap = false;
    this.waitForMapAndLoadRegions();
  }

  waitForMapAndLoadRegions() {
    if(this.nuts && this.nuts.map) {
      const regionFrom = this.object.relations.map((r) => r.regionFrom);
      const regionTo = this.object.relations.map((r) => r.regionTo);
      this.nuts.printRelations(regionFrom, regionTo)
    } else {
      setTimeout(() => {
        this.waitForMapAndLoadRegions();
      });
    }
  }

}
