import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';
import { HotkeyModule } from 'angular2-hotkeys';
// tslint:disable-next-line: max-line-length
import { GeoService, GoogleGeoService, I18nService, Nx3CasesModule, Nx3ComponentsModule, Nx3ResourceExtensionsModule, Nx3ServiceModule, Nx3StatemachineModule, Nx3TasksModule, Nx3TransportModule,Nx3BpmModule} from '@nx3/nx3-core-ui';
import { GuidedTourModule } from 'ngx-guided-tour';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { applicationConfig } from './app.config';
import { ComponentsModule } from './components/components.module';
import { PagesModule } from './pages/pages.module';
import { SharedModule } from 'projects/shared/src/lib/shared.module';
@NgModule({
  providers: [
    { provide: GeoService, useClass: GoogleGeoService }
  ],
  declarations: [
    AppComponent
  ],
  entryComponents: [],
  exports: [
    BrowserModule,
    BrowserAnimationsModule
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HotkeyModule.forRoot({
      cheatSheetCloseEsc: true,
    }),
    TranslateModule.forRoot(I18nService.createTranslateConfig()),
    GuidedTourModule.forRoot(),
    Nx3ServiceModule.forRoot(applicationConfig),
    Nx3ComponentsModule,
    Nx3ResourceExtensionsModule,
    Nx3TransportModule,
    Nx3TasksModule,
    Nx3CasesModule,
    Nx3StatemachineModule,
    ComponentsModule,
    PagesModule,
    AppRoutingModule,
    SharedModule,
    Nx3BpmModule,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
