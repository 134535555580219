import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  AccessDeniedComponent,
  AuthGuard,
  BackofficeDetailPageComponent,
  BootstrapResolver,
  FullLayoutComponent,
  LAYOUT_ROUTING_CONFIG,
  LoginComponent,
  NetworkErrorComponent,
  PersonInvitationComponent,
} from '@nx3/nx3-core-ui';
import { CarrierResolver } from './carrier-resolver';

export function getBackofficeModule() {
  const backofficeModule = () =>
    import('./lazylayout/lazylayout.module').then((m) => m.LazyLayoutModule);
  return backofficeModule;
}

const layoutRoutes: Routes = [
  {
    path: 'partner/:partnerNr',
    component: BackofficeDetailPageComponent,
    data: {
      reuseRoute: false,
    },
    resolve: {
      bootstrap: CarrierResolver,
    },
  },
];

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: '',
    component: FullLayoutComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent,
        resolve: {
          bootstrap: BootstrapResolver,
        },
      },
      {
        path: 'networkerror',
        component: NetworkErrorComponent,
      },
      { path: 'person-invitation', component: PersonInvitationComponent },
      {
        path: 'denied/:reason',
        component: AccessDeniedComponent,
      },
    ],
  },
  /*  {
    path: '',
    canActivate: [AuthGuard],
    children: createBackofficeRoutes(layoutRoutes),
  }, */
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: getBackofficeModule(),
  },
  {
    path: '**',
    redirectTo: '/dashboard',
  },
  {
    path: '**',
    redirectTo: 'login',
  },
];

const routerModuleRoot = RouterModule.forRoot(routes, {
  useHash: true,
  enableTracing: false,
});
@NgModule({
  imports: [routerModuleRoot],
  exports: [RouterModule],
  providers: [
    {
      provide: LAYOUT_ROUTING_CONFIG,
      useValue: {
        layoutRoutes,
      },
    },
  ],
})
export class AppRoutingModule {}
