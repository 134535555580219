import {
  DetailConfig,
  EntityDataQualityComponent,
  ListConfig,
  Nx3Api,
  ResourceExtension,
} from '@nx3/nx3-core-ui';

export class DefaultExtension extends ResourceExtension {
  constructor(public nx3: Nx3Api, public resourceName: string) {
    super(resourceName, nx3);
  }

  configureDetail(config: DetailConfig): void {
    config.disabledComponents.push(EntityDataQualityComponent);
    super.configureDetail(config);
  }

  configureList(config: ListConfig): void {
    config.enableGuidedTour = false;
    config.enableListSettings = false;
  }
}
