import {
  AuditLogApprovalUserTaskExtension,
  CommentsComponent,
  DynamicComponentConfig,
  EditorEvent,
  ModalComponent,
  Nx3Api,
} from '@nx3/nx3-core-ui';

export class CustomAuditLogApprovalUserTaskExtension extends AuditLogApprovalUserTaskExtension {
  modalInstance: ModalComponent;

  constructor(public nx3: Nx3Api, resourceName = 'auditlogapprovalusertask') {
    super(nx3, resourceName);
  }

  onEditorInit(event: EditorEvent): void {
    super.onEditorInit(event);
    event.component.metadata.getFieldByName('commentary').maxLength = 500;
    event.component.config.componentExtensions.addEastComponent(
      new DynamicComponentConfig(
        CommentsComponent,
        {
          commentStreamHeight: '30vh',
        },
        undefined,
        ['d-block', 'ms-3', 'mb-4', 'border', 'rounded', 'p-2']
      ),
      6
    );
  }
}
